const primary = {
  white: '#fff',
  black: '#000',
  yellow: '#',
  orange: '',
  gray: '',
  blue: '#7ABDFF',
  red: '#ff0000'
}


export const defaultThemeColors = {
  //Type: primary
  primary,
  //Type: label
  label: {
    bg: '#24353B',
    closeBtnBg: '#E6402E'
  },
  //Type: chat
  chat: { 
    bg: '#fff',
    shadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',

    success: '#34C759',
    error: '#E53935',
    red: '#E6402E', //yes
    orange: '#FF9500',
    yellow: '#FFCC00',
    green: '#34C759',
    teal: '#5AC8FA',
    blue: '#0077FF', //yes
    indigo: '#5856D6',
    purple: '#AF52DE',
    pink: '#FF2D55',
    gray: {
      '01': '#9D9592', //yes
      '02': '#AEAEB2',
      '03': '#E5EAF3', //yes
      '04': '#D1D1D6',
      '05': '#E5E5EA',
      '06': '#F2F2F7',
      '07': '#8097C3'
    },
    blue_: {
      '01': '#013087',
      '08': '#ecf7f9'
    },
    user: {
      name: '#101828',
      time: '#101828',
      avatarBg: 'EFEFEF'
    },
    message: {
      bg: {
        me: '#E3F0FF',
        you: '#E3E8EC'
      },
      text: {
        me: '#101828',
        you: '#101828'
      },
      icon: {
        me: '#101828',
        you: '#101828'
      }
    }
  }
}



export const COLORS = {
  defaultThemeColors
}