import styles from './app.module.scss'
import classNames from 'classnames'
import { useEffect} from 'react'
import { Label } from './components/views/Label/Label'
import { ChatWindows } from './components/ui/chat-windows/ChatWindows'
import { useRedux } from './components/hooks/useRedux'
import { OPEN_CLOSE_CHAT, SET_ACTIVE_VIEW, SET_CHAT_HISTORY, SET_INJECT_MODE} from './redux/types'
import {Header} from './components/views/commun/header/Header'
//import { getColor } from './assets/Js/modules/styles/styles.module'
import { Footer } from './components/views/commun/footer/Footer'
import { Body } from './components/views/commun/body/Body'
import { DepartmentsList } from './components/lists/departments/departmentsList/DepartmentsList'
import { useStorage } from './components/hooks/useStorage'
import { useMessage } from './components/hooks/messages/useMessage'
import { Context } from './Context'
import { useSelector } from 'react-redux'
import { Dialogs } from './components/lists/dialogs/Dialogs'
import { DialogMessages } from './components/lists/dialogs/DialogMessages'
import { ENV_JS } from './utils/env_module'
import { useActions } from './components/hooks/actions/useActions'



const App = ({inject=false, typeUser, grpDialogId, data}) => {
  const { REDUCER_DISPASH} = useRedux()
  const isOpenChat = useSelector(state => state.defaultReducer?.chatIsOpen??false)//REDUCER_STATE?.defaultReducer?.chatIsOpen??false
  const variablesReducer = useSelector(state => state.variablesReducer??false)//REDUCER_STATE?.variablesReducer?.openDepartments??false
  const {openGrpDialog} = useActions()
  const {InitStorage, StorageListerner} = useStorage()
  const useMessageHook = useMessage()
  
  const openDepartments = variablesReducer?.openDepartments
 // const clientData = data.clientData
  const clientName = data.clientName
  
  //const openChat = () => REDUCER_DISPASH({type: OPEN_CLOSE_CHAT, payload:true})
  const setOpenChat = (state) => REDUCER_DISPASH({type: OPEN_CLOSE_CHAT, payload:state})

  //===>> APP INIT
  useEffect(() => {
    //===> inject MODE
    REDUCER_DISPASH({type: SET_INJECT_MODE, payload:inject})
    const initStorageResponse = InitStorage({typeUser})
    initStorageResponse.then(response => {
      const clientData = response.clientData??null

      //===> Use Grp Dialog
      if(grpDialogId) {
        const dialogId = grpDialogId??null
        const siteId = clientData?.site_id??null
        const key = clientData?.key??null
        const channel = clientData?.channel??null
        if(dialogId&&siteId&&key&&channel) {
          openGrpDialog({dialogId, siteId, key, channel})
        }
      }
    })
    
    
    //===>>> Default close chat window
    if(!inject) {
      addEventListener('click', (e) => {
        const elem = e.target
        if((!(elem?.dataset?.name==='chat-window')&&!elem?.closest('[data-name="chat-label"]'))&&!elem?.closest('[data-name="chat-window"]')) {
          if((elem?.dataset?.closeW==='false')||elem?.closest('[data-close-w="false"]')) return
          setOpenChat(false)
        } else
        if((elem?.dataset?.closeW==='true')||elem?.closest('[data-close-w="true"]')) REDUCER_DISPASH({type: OPEN_CLOSE_CHAT, payload:true})
      })
    }
    //===>>>
    StorageListerner({typeUser})

  }, [])

  let managerId, managerToken, CALLPY_ORIGIN_URL, siteId, clientId, domaine
  useEffect(() => {
    switch(typeUser) {
      case 'manager':
        CALLPY_ORIGIN_URL = window.m_callpy.env.serverUrl??ENV_JS.CALLPY_ORIGIN_URL
        managerId = ENV_JS?.MANAGER_DATA?.ID??null
        managerToken = ENV_JS?.MANAGER_DATA?.TOKEN??null
        siteId = ENV_JS?.CLIENT_DATA.SITE_ID??null
        clientId = ENV_JS?.CLIENT_DATA.ID??null
        domaine = ENV_JS?.CLIENT_DATA.DOMAINE??null
        if(managerId&&managerToken) {
          REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'MANAGER'})

          const formData = new FormData()
          formData.append('action', 'select_new_user')
          formData.append('site', domaine)
          //formData.append('oldSite', '')
          formData.append('siteId', siteId)
         // formData.append('oldSiteId', 0) 
          formData.append('managerId', managerId)
          //formData.append('oldId', 0)
          formData.append('newId', clientId)
          formData.append('withHistory', 1)
          //formData.append('offSelect', 0)
          formData.append('where', 'to_php')

          const checkResponse =  fetch(`${CALLPY_ORIGIN_URL}/mngr/`, {
            method: 'POST',
            headers: {
              //'Content-Type': 'application/json',
              'Authorization': `Bearer ${managerToken}`
            },
            body: formData
          })
          if(checkResponse?.status===200) {
            const checkResponseData = checkResponse.json()
            const chatHistory = checkResponseData?.history??null
            if(chatHistory?.length) REDUCER_DISPASH({type: SET_CHAT_HISTORY, payload:chatHistory})
          }

        }
        break
    }
  }, [])

  const activeView = grpDialogId?'SELECTED_DIALOG':(variablesReducer?.activeView)

  const bodyActiveView = openDepartments&&<DepartmentsList/>
  let VIEW, typeUserName
  switch (activeView) {
    case 'CHAT':

      VIEW = <>
       <Header config={{showMenu:false, type:typeUserName, inject}}  />
       <Body activeView={bodyActiveView}/>
       <Footer  config={{typeDialog:"p2p", typeUser:{typeUserName}, inject}}   />
      </>
      
      break
    case 'DEPARTMENTS_LIST':
      VIEW = <>
        <Header />
        <DepartmentsList/>
      </>
      break
    case 'DIALOGS_LIST':
      VIEW = <>
        <Header config={{showMenu:false, showConsultant:false}}/>
        <Dialogs/>
      </>
      break
    case 'SELECTED_DIALOG':
      VIEW = <>
        <Header config={{showMenu:false, showConsultant:false, type:typeUserName, inject}}/>
        <DialogMessages/>
        <Footer config={{typeDialog:"group", typeUser:{typeUserName}, inject, clientName}}/>
      </>
      break
    case 'MANAGER':
      typeUserName = 'manager'
      VIEW = <>
        <Header config={{showMenu:false, showConsultant:false, type:{typeUserName}, inject}}/>
        <Body typeUser={typeUserName} />
        <Footer typeDialog="p2p" typeUser={typeUserName} inject={inject}/>
      </>
      break
  }
  
  return (
    <Context.Provider value={{useMessageHook}}>
      <div className={classNames(styles.app, {[styles['app--inject']]:inject})}>
        {!inject&&<Label />}
        <ChatWindows open={inject?inject:isOpenChat} config={{inject}}>
          {VIEW}
        </ChatWindows>
      </div>
    </Context.Provider>
  )
}
export default App
