import { SET_MANAGERS_LIST } from '../types'


const initialState = {}
export const managersListReducer = (state=initialState, action) => {
  switch (action.type) {
    case SET_MANAGERS_LIST:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
      
  }
}