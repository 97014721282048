import { SET_SELECTED_FORMS } from '../types'

const initialState = []
export const selectedFormsReducer = (state=initialState, action) => {
  switch (action.type) {
    case  SET_SELECTED_FORMS:
      return [
        ...state,
       ...action.payload
      ]
    default:
      return state
      
  }
}