import classNames from 'classnames'
import styles from './styles.module.scss'

export const Field = ({data, additionalData}) => {
  const formId = data?.formId??null
  const fieldId = data?.id??null
  const fieldSetting = additionalData?.fields??null
  console.log('fieldSetting',  additionalData, fieldSetting)
  const  requeried = Number(data?.required)??0
  
  let FIELD
  switch (data.type) {
    case 'INPUT':
      
      FIELD = <input 
        type="text" 
        name={`field_${fieldId??''}`} 
        className={classNames(styles.field)}  
        placeholder={data?.name??''}
        data-required={requeried===0?'true':'false'}
        />
      break;
    case 'BUTTON':
      

      const handlerClickBtn = () => {
        const mCallpy = window?.m_callpy??null
        if(mCallpy) {
          mCallpy?.m_autos?.checkAllConditions({
            form_id:formId,
            button_id: fieldId
          })
          console.log('success send', formId, fieldId)
        }
      }
      
      FIELD = <button 
        data-id={fieldId??''} 
        data-required={requeried===0?'true':'false'}
        style={fieldSetting[fieldId]}
        className={classNames(styles.fieldBtn)}  
        onClick={handlerClickBtn}
        >
          {data?.name??'Name'}
        </button>
      break;
  }
console.log('FIELD', FIELD)
  return (
    <div className={classNames(styles.fieldWrap)}>
      {
        FIELD
      }
    </div>
  )
}
