import classNames from 'classnames'
import styles from './styles.module.scss'

export const DepartmentsItem = ({data, ...props}) => {
  return (
    <div  className={styles.departmentsItem}>
        <div className={styles.departmentsItemInner}>
          <div className={styles.departmentsItemBody}>
            <div className={styles.departmentsItemTitle}>
              {data.name??'Department Name'}
            </div>
            <ul className={styles.departmentsItemListManagers}>
              <li className={styles.departmentsItemListManagersItem}></li>
            </ul>
          </div>
          <button className={styles.departmentsItemBtn}>Выбрать</button>
        </div>
      </div>
  )
}
