import classNames from 'classnames'
import styles from './styles.module.scss'
import { useEffect, useRef, useState } from 'react'
import { recordTimeWiewFormat } from '../../../utils/app_module'
import { Icon } from '../icons/Icon';

export const AudioPlayer = ({url, ...props}) => {
  const [audioDuration, setAudioDuration] = useState(0)
  const [playerAction, setPlayerAction] = useState('play')
  const [playPauseTimeId, setPlayPauseTimeId] = useState()
  const playerRef = useRef()


  const playerPlay = (audioElem) => {
    audioElem.play()
    const _playPauseTimeId = setInterval(() => {
      const currentTime = Math.ceil(audioElem.currentTime)
      setAudioDuration(currentTime)
    }, 1000)
    setPlayerAction('pause')
    setPlayPauseTimeId(_playPauseTimeId)
  }

  const playerPause = (audioElem) => {
    audioElem.pause()
    setPlayerAction('play')
    if(playPauseTimeId)  {
      clearTimeout(playPauseTimeId)
      setPlayPauseTimeId(null)
    }
  }

  const playerStop = (audioElem) => {
    playerPause(audioElem)
  }

  const handlerPlayPause = () => {
    const audioElem = playerRef.current
    switch (playerAction) {
      case 'play':
        playerPlay(audioElem)
        break
    
      case 'pause':
        playerPause(audioElem)
      break

    }
  }

  useEffect(() => {
    const audioElem = playerRef.current
    if(audioElem) {
      audioElem.addEventListener('loadedmetadata', function () {
        if(audioElem.duration == Infinity) {
          audioElem.currentTime = 1e101
          audioElem.ontimeupdate = () => {
            audioElem.ontimeupdate = () => {
              return
            }
            audioElem.currentTime = 0
            return
          }
          setTimeout(() => {
            const _audioDuration = audioElem.duration
            setAudioDuration(_audioDuration)
            
          }, 500)
        }
      })

      //==>>> OnEnd
      audioElem.onended = () => {
        playerStop(audioElem)
      }
    }
  }, [])

  return (
    <div className={classNames(styles.AudioPlayer)}>
      <audio src={url} ref={playerRef} className={classNames(styles.AudioPlayerField)}/>
      <div className={classNames(styles.AudioPlayerInner)}>
        <button className={classNames(styles.AudioPlayerControlBtn)} onClick={handlerPlayPause}><Icon name={playerAction} bg="#fff" width="9px" height="12px"/></button>
        <div className={classNames(styles.AudioPlayerTrack)}></div>
        <span className={classNames(styles.AudioPlayerTimerZone)}>{recordTimeWiewFormat(audioDuration)}</span>
      </div>
    </div>
  )
}
 