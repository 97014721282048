import {
  OPEN_CLOSE_CHAT
} from './../types'

const initialState = {
  chatIsOpen: false
}

export const defaultReducer = (state=initialState, action) => {
  switch (action.type) {
    case OPEN_CLOSE_CHAT:
      return {
        ...state,
        chatIsOpen: action.payload
      }
 
    default:
      return state
      
  }
}