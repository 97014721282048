import { useDispatch, useSelector } from 'react-redux'

export const useRedux = () => {
  const REDUCER_STATE = useSelector(state => state)
  const GET_REDUCER_STATE = useSelector
  const REDUCER_DISPASH = useDispatch()
  const GET_REDUCER_DISPASH = useDispatch


  const REDUX_GET_ITEM = (item) => {
    if(REDUCER_STATE[item]) return REDUCER_STATE[item]
  }

  return {REDUCER_STATE, REDUCER_DISPASH, GET_REDUCER_STATE, GET_REDUCER_DISPASH, REDUX_GET_ITEM}
}

// import { useDispatch, useSelector } from 'react-redux'

// export const useEnv = () => {
//   const ReducerState = useSelector(state=>state)
//   const reducerDispash = useDispatch()

//   const ENV = process.env??{}

//   const localstorageName = ENV.REACT_APP_LOCALSTORAGE_NAME
//   const REDUCER_STATE = ReducerState
//   const TOKEN = ReducerState?.AuthReducer?.token??null
//   const AUTHORIZATION = `Bearer ${TOKEN}`
 

//   return {ENV, TOKEN, AUTHORIZATION, REDUCER_STATE, localstorageName}
// }