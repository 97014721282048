import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
//import thunk from 'redux-thunk' //
import { createStore  } from 'redux' //, compose, applyMiddleware 
import { Provider } from 'react-redux'
import { rooteReducer } from './redux/reducer'
import App from './App'
//import 'normalize.css'
import './assets/styles/styles.scss'
import { ENV_JS } from './utils/env_module'

// import './modules/global'
// import './modules/callpyGlobal'
// import './modules/$jcallpy'
// import './modules/ccallpy'
// import './modules/ccookie'
// import './modules/cloader'
// import './modules/clogger'
// import './modules/cmessage'
// import './modules/cbridge'
// import './modules/dklab_realplexor'
// import './modules/capi'
// import './modules/cstorage'
// import './modules/cuser'
// import './modules/cflash'
// import './modules/ccontrol'
// import './modules/cactivity'
// import './modules/cautos'
// import './modules/cuser_chat'
// import './modules/view_chat'



class CallpyChat {

  constructor (opt={}) {
    this.storageName = 'CallpyApp'
    this.globalTimestamp = null
    this.storageData
    this.targetBoxId = opt?.targetBoxId??null
    this.mode = 'prod'
    this.serverUrl = opt?.serverUrl??ENV_JS.CALLPY_SERVER
    this.isReady = null
    this.timerId = null
    this.inject = opt?.inject??false
    this.typeUser = opt?.typeUser??'client' // client || manager ||
    this.grpDialogId = opt?.grpDialogId??null
    this.initModule = opt?.initModule??false,
    this.clientName = opt?.clientName??null

    const _THIS = this

    if (document.readyState !== 'loading') {
      _THIS.INIT()
    } else {
      document.addEventListener('DOMContentLoaded', function () {
        _THIS.INIT()
      })
    }
  }

  INIT () {
    if(this.targetBoxId) {
      const callpyRoot = this.mode==='dev'? document.createElement('div'):document.getElementById(this.targetBoxId)
      if(callpyRoot) {
        callpyRoot.classList.add('callpy-chat-root')
        this.inject?callpyRoot.classList.add('callpy-chat-root--inject'):''
       if(this.mode==='dev') document.body.appendChild(callpyRoot)
        const root = ReactDOM.createRoot(callpyRoot)
        //const store = createStore(rooteReducer, compose(applyMiddleware(thunk),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()))
        const store = createStore(rooteReducer)
        const currenTime = new Date().getTime()
        if(this.globalTimestamp) {
          //const cashTime = this.globalTimestamp*1000
        } else {
          this.globalTimestamp = currenTime
        }

        if(this.storageName) {
          this.timerId = setInterval(() => {
            if(this.initModule) window.m_callpy.init()
            this.isReady = (window?.m_callpy?.initialize_data!==undefined) && (window?.m_callpy?.client_data?.key!==undefined) && (window?.m_callpy?.client_data?.channel!==undefined) && (window?.m_callpy?.client_data?.site_id!==undefined) && (window?.m_callpy?.m_chat) && (window?.m_callpy?.managers_list) && (window?.m_callpy?.client_data?.dialogs!==undefined)
            const data = {
              clientData: window.m_callpy.client_data,
              managersList: window.m_callpy.managers_list,
              dialog: window.m_callpy.client_data.dialogs,
              grpDialogId: this.grpDialogId,
              clientName: this.clientName
            }
            if(this.isReady) {
              clearTimeout(this.timerId)
              if(!this.isReady?.history) window.m_callpy.initialize_data.history = [] 
              if(!window?.m_callpy?.env) window.m_callpy.env = {}
              window.m_callpy.env.serverUrl = this.serverUrl
              window.m_callpy.env.grpDialogId = this.grpDialogId
              
              root.render(
                <BrowserRouter>
                  <Provider store={store}>
                    <App inject={this.inject} typeUser={this.typeUser} grpDialogId={this.grpDialogId} data={data}/>
                  </Provider>
                </BrowserRouter>
              )
              return true
            }
            return false
          }, 1000)
        }
      } else {
        console.warn('Not found element: "targetBoxId" in document')
      }
    } else {
      console.warn('Not found: "targetBoxId"')
    }
  }

  STORAGE () {
    const storageName = this.storageName
    const get = () => {
      let storageData = JSON.parse(localStorage.getItem(storageName))??null
      if(storageData) {
        this.storageData = storageData
      } else {
        storageData = {
          //class: this,
          styles: {
            timestamp: Date.now(),
            data:''
          },
          scripts: {
            timestamp: Date.now(),
            data:''
          },
          store: {}
        }
        this.storageData = storageData
        localStorage.setItem(storageName, JSON.stringify(storageData))
      }
      return storageData
    }
    return {
      get
    }
  }
}
window.typeUser = 'client' // 'client' || 'manager'
window.CallpyChat = CallpyChat

// new CallpyChat({
//   targetBoxId:'root', 
//   mode:'prod',
//   serverUrl: 'https://kkombeto.dev.callpy.com',
//   typeUser: window.typeUser, // 'client' || 'manager'
//   inject: true,  // true || false
//   initModule: true,
//   grpDialogId: 281,  //   (only with inject:true)
//   clientName: 'Kevinpak'
// }) 





