import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import styles  from './styles.module.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ENV_JS } from '../../../utils/env_module'

const importAll = require => 
  require.keys().reduce((acc, next) => {
    acc[next.replace('./', '')] = require(next)
    return acc
  }, {})
const images = importAll(require.context('./svg', false, /\.(png|jpe?g|svg)$/))

const useStyles = createUseStyles({
  icon: {
    display: 'inline-block',
    minWidth: (props) => props.width??props.size??'1.2rem',
    minHeight: (props) => props.height??props.size??'1.2rem',
    //'-webkit-mask-image' : (props) => `url(${images[props.iconName]?.default})`,
    '-webkit-mask-image' : (props) => `url(${props.sourcePath}${images[props.iconName]})`,
    '-webkit-mask-size': (props) => `${props.width??props.size??'1.2rem'} ${props.height??props.size??'1.2rem'}`,
    '-webkit-mask-repeat': 'no-repeat ',
    '-webkit-mask-position': 'center left',
    backgroundColor: (props) => props.bg??props.colorPrimaryGrey,

    '&:hover': {
      backgroundColor: (props) => {
        return props.hoverBg??props.bg??props.colorPrimaryGrey
      }
    }
  }

})

export const Icon = ({name, bg, hoverBg, width, height, size, className, disabled, ...props}) => {
  const inject = useSelector(state => state?.variablesReducer?.inject)
  //const [iconName, setIconName] = useState
  const colorPrimaryGrey =   '#929A9D'
  const iconName = `icon-${name??'button-close'}.svg`
  props.iconName = iconName
  props.colorPrimaryGrey = colorPrimaryGrey
  props.bg = bg
  props.hoverBg = hoverBg
  props.width = width
  props.size = size
  props.disabled = disabled
  props.sourcePath = inject?ENV_JS.CALLPY_SOURCE_PATH:''
  const classes = useStyles(props)

  // useEffect(()=> {
  //   //setIconName(`icon-${name??'button-close'}.svg`)
  //   
  // }, [name])

  
  return <i className={classNames(classes.icon, className, {[styles.iconDisabled]: disabled})}></i>
}

