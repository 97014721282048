import classNames from 'classnames'
import { nanoid } from 'nanoid'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { ENV_JS } from '../../../utils/env_module'
import { linkify } from '../../../utils/app_module';


export const Emojis = ({className,  onSelect, ...props}) => {
  const [selectedEmoji, setSelectedEmoji] = useState(null)
  const [TheMostEmogisUsedList, setTheMostEmogisUsedList] = useState(null)
  const CALLPY_STORAGE_NAME = ENV_JS?.CALLPY_STORAGE_NAME??null
  if(!CALLPY_STORAGE_NAME) return []

  
  const emojisData = [
    {id:1, emoji:'😁', label:''},
    {id:2, emoji:'😂', label:''},
    {id:3, emoji:'😃', label:''},
    {id:4, emoji:'😄', label:''},
    {id:5, emoji:'😅', label:''},
    {id:6, emoji:'😆', label:''},
    {id:7, emoji:'😇', label:''},
    {id:8, emoji:'😈', label:''},
    {id:9, emoji:'😉', label:''},
    {id:10, emoji:'😊', label:''},
    {id:11, emoji:'😋', label:''},
    {id:12, emoji:'😌', label:''},
    {id:13, emoji:'😍', label:''},
    {id:14, emoji:'😎', label:''},
    {id:15, emoji:'😏', label:''},
    {id:16, emoji:'😐', label:''},
    {id:17, emoji:'😑', label:''},
    {id:18, emoji:'😒', label:''},
    {id:19, emoji:'😓', label:''},
    {id:20, emoji:'😔', label:''},
    {id:21, emoji:'😕', label:''},
    {id:22, emoji:'😖', label:''},
    {id:23, emoji:'😗', label:''},
    {id:24, emoji:'😘', label:''},
    {id:25, emoji:'😙', label:''},
    {id:26, emoji:'😚', label:''},
    {id:27, emoji:'😛', label:''},
    {id:28, emoji:'😜', label:''},
    {id:29, emoji:'😝', label:''},
    {id:30, emoji:'😞', label:''},
    {id:31, emoji:'😟', label:''},
    {id:32, emoji:'😠', label:''},
    {id:33, emoji:'😡', label:''},
    {id:34, emoji:'😢', label:''},
    {id:35, emoji:'😣', label:''},
    {id:36, emoji:'😤', label:''},
    {id:37, emoji:'😥', label:''},
    {id:38, emoji:'😦', label:''},
    {id:39, emoji:'😧', label:''},
    {id:40, emoji:'😨', label:''},
    {id:41, emoji:'😩', label:''},
    {id:42, emoji:'😪', label:''},
    {id:43, emoji:'😫', label:''},
    {id:44, emoji:'😬', label:''},
    {id:45, emoji:'😭', label:''},
    {id:46, emoji:'😮', label:''},
    {id:47, emoji:'😯', label:''},
    {id:48, emoji:'😰', label:''},
    {id:49, emoji:'😱', label:''},
    {id:50, emoji:'😲', label:''},
    {id:51, emoji:'😳', label:''},
    {id:52, emoji:'😴', label:''},
    {id:53, emoji:'😵', label:''},
    {id:54, emoji:'😶', label:''},
    {id:55, emoji:'😷', label:''},
    {id:56, emoji:'😸', label:''},
    {id:57, emoji:'😹', label:''},
    {id:58, emoji:'😺', label:''},
    {id:59, emoji:'😻', label:''},
    {id:60, emoji:'😼', label:''},
    {id:61, emoji:'😽', label:''},
    {id:62, emoji:'😾', label:''},
    {id:63, emoji:'😿', label:''},
    {id:64, emoji:'🙀', label:''},
    {id:65, emoji:'🙅', label:''},
    {id:66, emoji:'🙆', label:''},
    {id:67, emoji:'🙇', label:''},
    {id:68, emoji:'🙈', label:''},
    {id:69, emoji:'🙉', label:''},
    {id:70, emoji:'🙊', label:''},
    {id:71, emoji:'🙋', label:''},
    {id:72, emoji:'🙌', label:''},
    {id:73, emoji:'🙍'}
  ]

  const handlerSelectEmoji = (item) => {
    setSelectedEmoji(item)
    if(onSelect) onSelect(item)
  }

  const EmojisList = emojisData.map(item => {
    const key = nanoid()
    return <li key={key}  className={classNames(styles.EmojisListItem)} onClick={()=> handlerSelectEmoji(item)} data-close-w="false">{item.emoji}</li>
  })


  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem(CALLPY_STORAGE_NAME))??null
    if(!storage?.emojis) storage.emojis = {theMostUsed:[], live:[]}
      const emojis = storage.emojis
      emojis.live.length=0

      const theMostUsed = emojis?.theMostUsed?[...emojis.theMostUsed]:[]
      if(theMostUsed?.length) {
        const theMostUsedData = []
        const theMostUsedSort = theMostUsed.filter(item=>item.count>=10).sort((a,b)=>b.count-a.count).slice(0, 6)
        if(theMostUsedSort?.length) {
          const TheMosEmojisUsedList = theMostUsedSort.map(item => {
            const key = nanoid()
            return <li key={key}  className={classNames(styles.EmojisListItem)} onClick={()=> handlerSelectEmoji(item)} data-close-w="false">{item.emoji}</li>
          })
          setTheMostEmogisUsedList(TheMosEmojisUsedList)
        }
      }
      localStorage.setItem(CALLPY_STORAGE_NAME, JSON.stringify(storage))
  }, [])

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem(CALLPY_STORAGE_NAME))??null
    const emojis = storage.emojis
    const live = emojis?.live??[]
    if(selectedEmoji) {
      const existEmoji = live.find(item=>item.id===selectedEmoji.id)
      if(existEmoji) {
        existEmoji.count += 1
      } else {
        live.push({...selectedEmoji, count:1})
      }
      localStorage.setItem(CALLPY_STORAGE_NAME, JSON.stringify(storage))
    }
  }, [selectedEmoji])
  
  return (
    <div className={classNames(styles.Emojis, className??'')}>
      <div className={classNames(styles.EmojisInner)}>
        {
          TheMostEmogisUsedList &&
          <section className={classNames(styles.EmojisSection)}>
            <div className={classNames(styles.EmojisSectionTitle)}>Часто используемые</div>
            <ul className={classNames(styles.EmojisList)}>{TheMostEmogisUsedList}</ul>
          </section>
        }
        
        <section className={classNames(styles.EmojisSection)}>
          <div className={classNames(styles.EmojisSectionTitle)}>Эмоции</div>
          <ul className={classNames(styles.EmojisList)}>{EmojisList}</ul>
        </section>
      </div>
    </div>
  )
}
