//import { AppXMLHttpRequest } from '../utils/app_module'
import { ENV_JS } from '../utils/env_module'



export const DIALOGS_QUERIES = {
  history: async (opt={}) => {
    
    const dialogId = opt.dialogId??null
    const siteId = opt.siteId??null
    const key = opt.key??null
    const channel = opt.channel??null
    const BASE_URL = window.m_callpy.env.serverUrl??ENV_JS.CALLPY_ORIGIN_URL
    
    let result = null
    if(dialogId&&siteId&&key&&channel) {
      const formData = new FormData()
      formData.append('action', 'select_new_dialog')
      formData.append('dialog_id', dialogId)
      formData.append('site_id', siteId)
      formData.append('part_site_id', siteId)
      formData.append('participants', true)
      formData.append('history', true)
      formData.append('key', key)
      formData.append('type', 1)
      formData.append('channel', channel)
      formData.append('where', 'to_fms')
      // const xhr = AppXMLHttpRequest(`${BASE_URL}d`, {
      //   method: 'POST',
      //   body: formData
      // })
      const checkResponse = await fetch(`${BASE_URL}/d`, {
        method: 'POST',
        body: formData
      })
      if(checkResponse?.status===200) {
        const response = await checkResponse.json()
        if(response) {
          result = {
            history: response?.history??null,
            participants: response?.participants??null
          }
        }
      }
      
    }
    return result
  }
}