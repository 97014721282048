import classNames from 'classnames'
import styles from './styles.module.scss'
import { createUseStyles } from 'react-jss'
import { getColor } from '../../../../assets/Js/modules/styles/styles.module'
import { Icon } from '../../../ui/icons/Icon'
import { Message } from '../../message/Message'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import { useMessage } from '../../../hooks/messages/useMessage'
import {useSelector} from 'react-redux'
import { Context } from '../../../../Context'
import { useStorage } from '../../../hooks/useStorage'
import { ControlSelectedMessages } from '../../message/controlSelectedMessages/ControlSelectedMessages'
import { useRedux } from '../../../hooks/useRedux'
import { SET_CHAT_HISTORY } from '../../../../redux/types'
import { Forms } from '../../forms/Forms'


export const Body = ({activeView=null, typeUser, ...props}) => {
  const [history, setHistory] = useState([])
  //const [MESSAGES, setMESSAGES] = useState([])

  const chatReducer =  useSelector(state => state.chatReducer)
  const selectedFormsReducer =  useSelector(state => state.selectedFormsReducer)

  const {useMessageHook} = useContext(Context)

  const trackStrip = useRef()

  //==>>Handler::: Scroll
    const scrollTrackToBottom = () => {
    const block = trackStrip?.current??null
    if(block) {
      const scrollHeight = block.scrollHeight
      block.scrollTo({top:scrollHeight, behavior:'smooth'})
    }
  }


  //==>> MESSAGES
  let MESSAGES 
  if(history?.length) {
    let countNum = 0
    MESSAGES = history.map((item, index, arr) => {
      countNum++
      const keyId = nanoid()
      let injectDate = false
      let dayDate = null

      const prevItem = index>0?arr[index-1]:null
      const nexItem = (index+1<=arr.length-1)?arr[index+1]:null
      const concatMsg = prevItem?.recieved===item?.recieved
      const nextConcatMsg = nexItem?.recieved===item?.recieved
      const type = nexItem?.type??0

      let prevTimestampDate = prevItem?prevItem.timestamp:null
      let prevDate = new Date(prevTimestampDate*1000)

      let messageTimestampDate = item.timestamp
      let messageDate = new Date(messageTimestampDate*1000)

      
      if(countNum===1||prevDate&&!(prevDate?.getDate()===messageDate?.getDate())&&(prevDate?.getMonth()===messageDate?.getMonth())&&(prevDate?.getFullYear()===messageDate?.getFullYear())) {
        injectDate = true
        const formatter = new Intl.DateTimeFormat('ru', {
          day:'numeric',
          month:'long',
          year:'numeric'
        })
        messageDate = formatter.format(messageDate)
      }

      if(history?.length===countNum) {
        scrollTrackToBottom()
      }

      const recieved = item?.recieved??null
      const isMe = (recieved===0)

      const messageData = {
        category:2,
        from:isMe?1:2,
        message: item?.message??'',
        type,
        item
      }
      if(item.message) messageData.message = item?.message??''
      if(item.path) messageData.path = item?.path??''
      return <Fragment key={keyId}>
        {
          injectDate
            &&
          <Message  
            data={{
              category:1,
              from:3,
              message: messageDate,
              item
            }}
            opt={{

            }}
          />
        }

        <Message  
          data={messageData}
          opt={{
            concatMsg,
            prevItem,
            nextConcatMsg,
          }}

        /> 
      </Fragment>

    })
  }

 
  //==>>Hook-useEffect::: History
  useEffect(() => {    
    //==>> Get History
    const _history = chatReducer?.history
    if(_history?.length) setHistory(_history)
    setTimeout(()=>scrollTrackToBottom(), 200)
  }, [chatReducer])

  //==>>Hook-useEffect::: Forms 
  useEffect(() => {
    switch(typeUser) {
      case 'client': //for client
        const siteInfo = clientDataReducer?.site_info??null
        const forms = siteInfo?.forms
        if(forms) {
          const formId = selectedFormsReducer[0]
          const formDate = forms[formId]
          if(MESSAGES?.length) {
            const date = new Date()
            const newHistory = JSON.parse(JSON.stringify(history))
            newHistory.push({
              from:2,
              message: 'Form',
              category:2,
              timestamp: date.getTime(),
              _id:{$id:formId},
              type:7,
              form:formDate
            })
            setHistory(newHistory)
            setTimeout(()=>scrollTrackToBottom(), 200)
          }
        }
      break
    }
  }, [selectedFormsReducer])


  //====>>> BodyContent
  const BodyContent = activeView?
    <div className={classNames(styles.bodyView)}>
      {activeView}
    </div>
    :
    <>
      <ControlSelectedMessages/>
      <div className={classNames(styles.bodyTrack)} ref={trackStrip}>
        <div className={classNames(styles.bodyTrackInner)}>
          {MESSAGES} 
        </div>
      </div>
      {useMessageHook.citationsBlock}
    </>


  return (
    <div className={classNames(styles.body)}>
      {BodyContent}
    </div>
  )
}
