import classNames from 'classnames'
import styles from './styles.module.scss'
import { createUseStyles } from 'react-jss'
import { getColor } from '../../../../assets/Js/modules/styles/styles.module'
import { Icon } from '../../../ui/icons/Icon'
import {useSelector ,  useDispatch} from 'react-redux'
import { OPEN_CLOSE_CHAT, SET_ACTIVE_VIEW, SET_VARIABLES } from '../../../../redux/types'
import { Dropdown } from '../../../ui/dropdown/Dropdown'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { $_GET } from '../../../../utils/app_module'

const colorBlue = getColor({name:'blue'})
const colorWhite = getColor({name:'white', type:'primary'})
const colorBlack = getColor({name:'black', type:'primary'})

const useStyles = createUseStyles({
  header: {
    backgroundColor: colorBlue
  }
})

export const Header = ({config={}}) => {

  const showMenu = config?.showMenu??true
  const showConsultant = config?.showConsultant??true
  const type = config?.type??'client'
  const inject = config?.inject??false
  console.log('type', type, inject)
  const classes = useStyles()
  const siteInfo = useSelector(state => state?.clientDataReducer?.site_info??null)
  const managersList = useSelector(state => state?.managersListReducer??null)
  const clientData = useSelector(state => state?.clientDataReducer??null)
  const REDUCER_DISPASH = useDispatch()
  const [userRoleName, setUserRoleName] = useState(siteInfo?.chat_bottext??'Консультант')
  const [userName, setUserName] = useState(siteInfo?.managersList??'Name')

  console.log('siteInfo', siteInfo)

  //====> chat_bottext
  // useEffect(()=> {
  //   if(!inject) {
  //     setUserRoleName(siteInfo?.chat_bottext??'-')
  //   }
  // }, [siteInfo?.chat_bottext])

  //====> chat_toptext
  useEffect(()=> {
    if(!inject) {
      switch (type) {
        // case 'client':

        // break;
        case 'manager':
          const clientId = $_GET?.clientId??null
          setUserRoleName('Клиент')
          setUserName(clientId? `Неизвестно ${clientId}`:'Client Name')
        break;
      }
    }
  }, [type])

  // //====> Type
  // useEffect(()=> {
  //   setUserRoleName(siteInfo?.chat_bottext??'-')
  // }, [siteInfo?.chat_bottext])

  //====> chat_toptext
  useEffect(()=> {
    if(!inject) {
      if(clientData?.peer_key) {
        const peerKey = clientData.peer_key
        const userInfos = managersList[peerKey]

        let userNameTemplate = siteInfo?.chat_toptext?.trim()??''
        if(userNameTemplate) {
          const list = ["name", "subname", "position", "phone", "mail"]
          for (let i=0; i<list.length; i++) {
            const key = list[i]
            const item = `%${key}%`
            if(userNameTemplate.indexOf(item)>-1) {
              userNameTemplate = userNameTemplate.replace(item, userInfos[key])
            }
          }
          
        }
        setUserName(userNameTemplate)
      }
    }

  }, [siteInfo?.chat_toptext])


  const dropDownLabel = <><Icon name='burger' bg={colorWhite} size='16px'/></>

  //==>>
  const handlerCloseChatWindows = () => {REDUCER_DISPASH({type: OPEN_CLOSE_CHAT, payload:false})}

  //==> 
  const handlerSetDialogList = () => REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'DIALOGS_LIST'})

  const handlerSetDepartmentList = () => REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'DEPARTMENTS_LIST'})

  const handlerSetChatView = () => REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'CHAT'})


  //=======>>> TYPE
  let USER_TYPE_LODING = ''
  if(!inject) {
    switch (type) {
      case 'client':
        USER_TYPE_LODING = (
          <div className={classNames(styles.headerInner)}>
            <div className={classNames(styles.headerTopPanel)}>
              {!showMenu&&<button className={classNames(styles.headerTopPanelGoBackBtn)} onClick={handlerSetChatView} data-close-w="false"><Icon name='goback' bg={colorWhite}/></button>}
              <button className={classNames(styles.headerTopPanelCloseChatBtn)} onClick={handlerCloseChatWindows}><Icon name='close' bg={colorWhite}/></button>
              {
                showMenu &&
                <div className={classNames(styles.headerTopPanelControls)}>
                  <button className={classNames(styles.headerTopPanelControlsBtn)}><Icon name='call' bg={colorWhite} size='16px'/></button>
                  <button className={classNames(styles.headerTopPanelControlsBtn)}><Icon name='video' bg={colorWhite} size='16px'/></button>
                  <Dropdown label={dropDownLabel} opt={{className:styles.headerTopPanelControlsMenu ,labelClassName:styles.headerTopPanelControlsBtn}}>
                    <div className={styles.headerTopPanelControlsMenuContent}>
                      <ul>
                        <li className={classNames(styles.headerTopPanelControlsMenuItem)}>
                          <button  className={classNames(styles.headerTopPanelControlsMenuItemLink)}  onClick={handlerSetDialogList}  data-close-w="false">
                            <Icon name='chat' bg='#101828'/>
                            <span>Диалоги</span>
                          </button>
                        </li>
                        <li className={classNames(styles.headerTopPanelControlsMenuItem)}>
                          <button  className={classNames(styles.headerTopPanelControlsMenuItemLink)}  onClick={handlerSetDialogList}  data-close-w="false">
                            <Icon name='user-check' bg='#101828'/>
                            <span>Представиться</span>
                          </button>
                        </li>
                        <li className={classNames(styles.headerTopPanelControlsMenuItem)}>
                          <button  className={classNames(styles.headerTopPanelControlsMenuItemLink)}  onClick={handlerSetDepartmentList}  data-close-w="false">
                            <Icon name='shuffle' bg='#101828'/>
                            <span>Выбрать отдел</span>
                          </button>
                        </li>
                        <li className={classNames(styles.headerTopPanelControlsMenuItem)}>
                          <button  className={classNames(styles.headerTopPanelControlsMenuItemLink)}  onClick={handlerSetDialogList}  data-close-w="false">
                            <Icon name='call-ligth' bg='#101828'/>
                            <span>Заказать звонок</span>
                          </button>
                        </li>
                        <li className={classNames(styles.headerTopPanelControlsMenuItem)}>
                          <button  className={classNames(styles.headerTopPanelControlsMenuItemLink)}  onClick={handlerSetDialogList}  data-close-w="false">
                            <Icon name='star' bg='#101828'/>
                            <span>Оценить диалог</span>
                          </button>
                        </li>
                        <li className={classNames(styles.headerTopPanelControlsMenuItem)}>
                          <button  className={classNames(styles.headerTopPanelControlsMenuItemLink)}  onClick={handlerSetDialogList}  data-close-w="false">
                            <Icon name='message' bg='#101828'/>
                            <span>Отправить диалог</span>
                          </button>
                        </li>
                      
                        <li className={classNames(styles.headerTopPanelControlsMenuItem)}>
                          <button  className={classNames(styles.headerTopPanelControlsMenuItemLink)}  onClick={handlerSetDialogList}  data-close-w="false">
                            <Icon name='setting' bg='#101828'/>
                            <span>Настройки</span>
                          </button>
                        </li>
                        
                      </ul>
                    </div>
                  </Dropdown>
                </div>
              }
            </div>
            { 
              showConsultant &&
              <div className={classNames(styles.headerUserPanel)}>
                <div className={classNames(styles.headerUserAvatarWrap)}>
                  <div className={classNames(styles.headerUserAvatar)}>
                    <Icon name='default-avatar' bg={colorBlack} size='20px'/>
                  </div>
                  <span className={classNames(styles.headerUserPresenceState)}></span>
                </div>
                <div className={classNames(styles.headerUserContent)}>
                  <div className={classNames(styles.headerUserName)}>{userName}</div>
                  <div className={classNames(styles.headerUserRole)}>{userRoleName}</div>
                </div>
              </div>
            }
          </div>
        )
        break;
    
      case 'manager':
        USER_TYPE_LODING = (
          <div className={classNames(styles.headerInner)}>
            <div className={classNames(styles.headerTopPanel)}>
              <button className={classNames(styles.headerTopPanelCloseChatBtn)} onClick={handlerCloseChatWindows}><Icon name='close' bg={colorWhite}/></button>
            </div>
            <div className={classNames(styles.headerUserPanel)}>
              <div className={classNames(styles.headerUserAvatarWrap)}>
                <div className={classNames(styles.headerUserAvatar)}>
                  <Icon name='default-avatar' bg={colorBlack} size='20px'/>
                </div>
                <span className={classNames(styles.headerUserPresenceState)}></span>
              </div>
              <div className={classNames(styles.headerUserContent)}>
                <div className={classNames(styles.headerUserName)}>{userName}</div>
              </div>
            </div>
          </div>
        )
        break;
    }
  }

  //console.log('USER_TYPE_LODING==>', USER_TYPE_LODING, inject, type)

  
  return (
    <div className={classNames(styles.header, classes.header)}>
      {USER_TYPE_LODING}
    </div>
  )
}
