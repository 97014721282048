import classNames from 'classnames'
import { SET_ACTIVE_VIEW, SET_SELECTED_DIALOG } from '../../../redux/types'
import styles from './styles.module.scss'
import { useRedux } from '../../hooks/useRedux'
import { Icon } from '../../ui/icons/Icon'
import userImg1 from './../../../assets/images/defaults/user_1.png'
import { Dialog } from './Dialog'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import { AppXMLHttpRequest } from '../../../utils/app_module'
import { json } from 'react-router-dom'
import { useActions } from '../../hooks/actions/useActions'


export const Dialogs = () => {
  const {REDUCER_DISPASH} = useRedux()
  const dialogsReducer =  useSelector(state => state.dialogsReducer)
  const clientDataReducer =  useSelector(state => state.clientDataReducer)
  const [dialogsArr, setDialogsArr] = useState([])
  const {openGrpDialog} = useActions()
  console.log('dialogsReducer', dialogsReducer, dialogsArr)
  const handlerSetChatView = () => REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'CHAT'})
  //const handlerSetSelectedDialog = () => REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'SELECTED_DIALOG'})

  const siteId = clientDataReducer.site_id??null
  const clientKey = clientDataReducer.key??null
  const clientChannel = clientDataReducer.channel??null
  
  const select_New_Dialog = async dialogId => {
    openGrpDialog({dialogId,siteId,key:clientKey,channel:clientChannel})
  }

  

  const dialogsList = dialogsArr.map(item=> {
    const keyId = nanoid()
    const dialogId = Number(item.id)
    return <Dialog
      key={keyId}
      id={dialogId}
      name={item.name}
      lastMsg ='У нас представлены модели с диагонью диагонью'
      timestamp={item?.last_read_time??Number(item.create_time)}
      totalUnread={item.unread}
      onClick={()=> select_New_Dialog(dialogId)}
    />
  })


  useEffect(()=> {
    const dialogs = dialogsReducer?.dialogs??null
    if(dialogs?.length) {
      setDialogsArr(currentData => [...currentData, ...dialogs])
    }
    
  }, [dialogsReducer.dialogs])
  return (
    <> 
      <div className={classNames(styles.dialogs)}>
        <div className={classNames(styles.dialogs__body)}>
          <section className={classNames(styles.dialogs__section)}>
            <h3 className={classNames(styles.dialogs__sectionTitle)}>Текущий</h3>
            <ul className={classNames(styles.dialogs__sectionList)}>
          
              <Dialog
                name='Константин'
                lastMsg ='Оформляю заявку'
                timestamp={Number(1717977920)}
                totalUnread='16'
                userAvatar ={userImg1}
                type='default'
                onClick={handlerSetChatView} 
              />
               {/* <Dialog
                name='Консультация Sumsung S12+'
                lastMsg ='У нас представлены модели с диагонью диагонью'
                timestamp={Number(1717977920)}
                totalUnread='0'
              /> */}

              {dialogsList}
            </ul>
          </section> 
          <section className={classNames(styles.dialogs__section)}>
          </section> 
        </div> 
        <div className={classNames(styles.dialogs__footer)}>
          <button className={classNames(styles.dialogs__footerGobackBtn)} onClick={handlerSetChatView} data-close-w="false">
            <Icon name="go-back" bg="#0077FF"/>
            <span>Вернуться назад</span>
          </button>
        </div> 
      </div>
      
    </>
  )
}
