import classNames from 'classnames'
import styles from './styles.module.scss'
import { createUseStyles } from 'react-jss'
import { getColor } from '../../../../assets/Js/modules/styles/styles.module'
import { Icon } from '../../../ui/icons/Icon'
import { Link } from 'react-router-dom'
import { useContext, useRef, useState } from 'react'
import {useSelector} from 'react-redux'
import { ADD_NEW_MESSAGE } from '../../../../redux/types';
import { Emojis } from '../../../ui/emojis/Emojis'
import { useFile } from '../../../hooks/useFile'
import { userAudioRecord } from '../../../hooks/audio-record/useAudioRecord'
import { Context } from '../../../../Context'
import { AppXMLHttpRequest } from '../../../../utils/app_module'

const colorBlue = getColor({name:'blue'})
const colorWhite = getColor({name:'white', type:'primary'})
const colorBlack = getColor({name:'black', type:'primary'})

const useStyles = createUseStyles({
  footer: {
    backgroundColor: colorBlue
  },
  footerControlBlock: {
    backgroundColor: colorWhite
  },
  sendMessageField: {
    caretColor: colorBlue,
    backgroundColor: 'transparent'
  },
  footerBottom: {
    color: colorWhite
  },
  footerBottomLink: {
    color: colorWhite
  },
  Webmaster: {
    color: colorWhite,

    footerBottomLink: {
      color: colorWhite,
    }
  }
})


export const Footer = ({config={}}) => {
  const typeDialog = config.typeDialog??'p2p'
  const typeUser = config.typeUser??'client'
  const inject = config.inject??false
  const clientName = config?.clientName??''

  const [showSendMessageBtn, setShowSendMessageBtn] = useState(false)
  const [showEmojis, setShowEmojis] = useState(false)
  const variablesReducer =  useSelector(state => state.variablesReducer)
  const clientDataReducer =  useSelector(state => state.clientDataReducer)
  const {useMessageHook} = useContext(Context)
  const {sendMessage} = useMessageHook

  const {sendFile} = useFile()
  const msgField = useRef()
  const fileField = useRef()
  const {panel, start} = userAudioRecord((opts) => {
    const {file, fileName, handlerRemoveRecoder} = opts
    sendFile({file, messageType:9}, (response) => {handlerRemoveRecoder()})
  })

  
  

  const handlerStartChooseFile = () => {
    fileField.current.click()
  }

  const handlerFocus = () => setShowSendMessageBtn(true)
  const handlerFocusout = e => {
    const msgField = e.target
    if(msgField) {
      const message = msgField.value.trim()
      if(message.length===0) setShowSendMessageBtn(false)
    }
  }
 
  const handlerSendMessage = () => {
    const field = msgField?.current
    if(field) {
      const message = field.value.trim()
      if(message?.length) {
        const msgOptions = {message, typeUser, typeDialog, clientName}
        if(variablesReducer?.selectedMessageId) {
          const selectedMessageId = variablesReducer.selectedMessageId
          msgOptions.selectedMessageId = selectedMessageId
        }

        if(variablesReducer?.citationTextTarget) {
          msgOptions.citationTextTarget = variablesReducer.citationTextTarget
        }

        sendMessage(msgOptions, (response) => {
          field.value = ''
          field.focus()
          if(showEmojis) setShowEmojis(false)
          field.value = ''
          field.focus()
        })
        

        
      }
    }
  }

 

  const handlerKeyDow = e => {
    if(e.key==='Enter') {
      handlerSendMessage()
    }
  }

  const handlerSelectEmoji = (item) => {
    const field = msgField?.current
    if(item&&field) {
      const currentValue = field.value
      field.value = currentValue.concat(' ', item.emoji)
      field.focus()
    }
  }

  
  const handlerFileFieldChanged = e => {
    const field = e.target
    if(field) {
      const file = field.files[0]
      if(file) {
        sendFile({file}, (response) => {})
      }
    }
  }

  const handlerAudioStartRecord = e => {
    start({btn:e.currentTarget})
  }



  
  const classes = useStyles()
  return (
    <div className={classNames(styles.footer, classes.footer, {
      [styles['footer--inject']]:inject
    })}>
      <div className={classNames(styles.footerInner)}>
        {showEmojis&&<Emojis className={classNames(styles.Chatemojis)} onSelect={(response)=> handlerSelectEmoji(response)}/>}
        <div className={classNames(styles.footerControlBlock, classes.footerControlBlock)}>
          <button className={classNames(styles.emojisBtn)} onClick={()=>setShowEmojis(currentValue => !currentValue)}><Icon name='smile'/></button>
          <input  
            className={classNames(styles.sendMessageField, classes.sendMessageField)} 
            placeholder='ввод сообщения' 
            ref={msgField} 
            onFocus={handlerFocus} 
            onBlur={handlerFocusout}
            onKeyDown = {handlerKeyDow}
          />
          <input 
            type="file" 
            name="file" 
            className={classNames(styles.fileField)} 
            ref={fileField}
            onChange={handlerFileFieldChanged}
          />
          <div className={classNames(styles.footerControlBlockActions)}>
            <button className={classNames(styles.attachmentBtn)} onClick={handlerStartChooseFile}><Icon name='attachment'/></button>
            {showSendMessageBtn&&<button className={classNames(styles.sendBtn)} onClick={handlerSendMessage}><Icon name='send' bg={colorBlue}/></button>}
            {!showSendMessageBtn&&<button className={classNames(styles.sendBtn)} onMouseDown={handlerAudioStartRecord}><Icon name='microphone'/></button>}
            {panel}
          </div>
        </div>
        <div className={classNames(styles.footerBottom, classes.footerBottom)}>
          <span  className={classNames(styles.footerBottomWebmaster)}>Разработано в <Link to='#' className={classNames(styles.footerBottomLink, classes.footerBottomLink)}>Callpy</Link></span>
          <Link  className={classNames(styles.footerBottomLink, classes.footerBottomLink)} to='#'>Политика конфеденциальности</Link>
        </div>
      </div>
    </div>
  )
}
