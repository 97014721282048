import styles from './styles.module.scss'
import classNames from 'classnames'
import { getColor, getSize } from '../../../assets/Js/modules/styles/styles.module'
import { createUseStyles } from 'react-jss'


//horizontalPosition=> 'left' || 'right' || 'auto'

export const ChatWindows = ({children, horizontalPosition='right', open=false, config, props}) => {
  const chatWidth = getSize({name:'width'})
  const chatMinHeight = getSize({name:'minHeight'})
  const chatBg = getColor({name:'bg'})
  const chatBoxShadow = getColor({name:'shadow'})
  const inject = config?.inject??false

  //===>> CAHT POSITION
  const horizontalPositionStyle = {
    top: 0,
    bottom: 0
  }
  switch (horizontalPosition) {
    case 'left':
      horizontalPositionStyle.left = 0
      horizontalPositionStyle.right = 'initial'
      break
    case 'right':
      horizontalPositionStyle.right = 0
      horizontalPositionStyle.left = 'initial'
      break
  }

  const useStyles = createUseStyles({
    chatWindows: {
      width: inject?'initial':0,
      opacity:inject?'initial':0,
      transition: 'all .3s linear',
      position: inject?'static':'fixed',

      minHeight: inject?'initial':chatMinHeight,
      backgroundColor: chatBg,
      boxShadow: inject?'initial':chatBoxShadow,
      ...horizontalPositionStyle
    },
    isOpen: {
      width:inject?'100%':chatWidth,
      opacity: inject?'initial':1
    }
  })

  const classes = useStyles()

  return (
    <div className={classNames(styles.chatWindows, classes.chatWindows, {
      [classes.isOpen]:open
    })} {...props} data-name="chat-window">
      <div className={classNames(styles.chatWindows)} {...props}>
        {children}
      </div>
    </div>
  )
}
