
import { useRedux } from '../useRedux' 
import { DIALOGS_QUERIES } from '../../../queries/dialogs.queries'
import { SET_ACTIVE_VIEW, SET_GROUP_DIALOG_ID, SET_SELECTED_DIALOG } from '../../../redux/types'

export const useActions = (opt={}) => {
  const {REDUCER_DISPASH} = useRedux()

  //===>> 01::
  const setSelectedDialogMode = () => REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'SELECTED_DIALOG'})

  //==>> 02::
  const selectNewDialog = async (opt={}) => {
    const dialogId = opt?.dialogId??null
    const siteId = opt.siteId??null
    const key = opt.key??null
    const channel = opt.channel??null

    let result = null
    if(dialogId&&siteId&&key&&channel) {
      result = await DIALOGS_QUERIES.history({dialogId,siteId,key, channel})
    }
    return result
  }

  //==>> 03:: Open Grp Dialog
  const openGrpDialog = async (opt={}) => {
    const dialogId = opt?.dialogId??null
    const siteId = opt.siteId??null
    const key = opt.key??null
    const channel = opt.channel??null


    let result = null
    if(dialogId&&siteId&&key&&channel) {
      const selectNewDialogResponse = await selectNewDialog({dialogId,siteId,key,channel})
      REDUCER_DISPASH({type: SET_GROUP_DIALOG_ID, payload:dialogId})
      setSelectedDialogMode()
      if(selectNewDialogResponse?.history) {
        const selectedDialogHistory =  selectNewDialogResponse.history
        REDUCER_DISPASH({type: SET_SELECTED_DIALOG, payload:selectedDialogHistory})
        result = selectNewDialogResponse
      }
    }
    return result
  }


  return {
    selectNewDialog,
    setSelectedDialogMode,
    openGrpDialog
  }
}
