import classNames from 'classnames'
import styles from './styles.module.scss'
import { useEffect, useRef, useState } from 'react'

export const Tooltip = ({message, className, msgClassName, opt={}, children, ...props}) => {
  const [isVisible, setIsvisible] = useState(false)
  const tooltipRef = useRef()
  const tooltipMsgRef = useRef()
  const position = opt?.position??'top' // top || bottom

  useEffect(()=> {
    const elem = tooltipRef.current
    const elemMsg = tooltipMsgRef.current
    if(isVisible) console.log('tooltipRef', elemMsg.getBoundingClientRect())
    
  }, [isVisible])
  return (
    <span className={classNames(styles.tooltip, className??'')} onMouseEnter={()=>setIsvisible(true)} onMouseLeave={()=>setIsvisible(false)}>
      {
        isVisible&&
        <span className={classNames(styles.tooltipMessage, msgClassName??'', styles[`tooltipMessage--${position}`])} ref={tooltipRef}>
          <span className={classNames(styles.tooltipMessageContent, styles[`tooltipMessageContent--${position}`])} ref={tooltipMsgRef}>{message}</span>
        </span>
      }
      <span className={classNames(styles.tooltipContent)}>{children}</span>
    </span>
  )
}
