import styles from './styles.module.scss'
import classNames  from 'classnames'
import { Icon } from '../../ui/icons/Icon'
import { useEffect, useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { ENV_JS } from '../../../utils/env_module'
import { SET_VARIABLES } from '../../../redux/types'
import { AppXMLHttpRequest } from '../../../utils/app_module'

export const useMessage = () => {
  const REDUCER_DISPASH = useDispatch()
  const clientDataReducer = useSelector(state => state.clientDataReducer)
  const variablesReducer = useSelector(state => state.variablesReducer)
  const [showCitationsBlock, setShowCitationsBlock] = useState(false)
  const [citationContent, setCitationContent] = useState('')
  const [citationTextTarget, setCitationTextTarget] = useState()
  const [currentSiteId, setCurrentSiteId] = useState(null)
  const CALLPY_STORAGE_NAME = ENV_JS?.CALLPY_STORAGE_NAME??null
  if(!CALLPY_STORAGE_NAME) return []

  const [showSelectedMessagesBlock, setShowSelectedMessagesBlock] = useState(false)
  const [selectedMessagesContent, setSelectedMessagesContent] = useState('')
  const [selectedMessagesCounter, setSelectedMessagesCounter] = useState(1)

  if(!window.clientDataReducer) window.clientDataReducer = clientDataReducer
  

  /*========> START CITATION <============*/
  const handlerRemoveCitation = () => {
    setShowCitationsBlock(false)
    setCitationContent('')
    REDUCER_DISPASH({type: SET_VARIABLES, payload:{selectedMessageId:null, citationTextTarget:null}})
  }

  const startCitation = (opt={}) => {
    const {userName, text, messageId} = opt
    if(userName&&text&&messageId) {
      const box = (
        <div className={classNames(styles.partialCitation)}>
          <div className={classNames(styles.partialCitationInner)}>
            <span className={classNames(styles.partialCitationIcon)}><Icon name="quotes"/></span>
            <div className={classNames(styles.partialCitationBody)}>
              <div className={classNames(styles.partialCitationAuthor)}>{userName}</div>
              <div className={classNames(styles.partialCitationText)}>{text}</div>
            </div>
            <button className={classNames(styles.partialCitationCloseBtn)} onClick={handlerRemoveCitation} data-close-w="false"><Icon name="close" size="16px" hoverBg="#E6402E"/></button>
          </div>
        </div>
      )
      setCitationTextTarget(text)
      setCitationContent(box)
      setShowCitationsBlock(true)
      setSelectedMessagesCounter(current => current+1)
      //setCitationMssageId(messageId)
    }
  }

  const citationsBlock =  (
    showCitationsBlock
    &&
    <div className={classNames(styles.citationsBlock)}>
      {citationContent}
    </div>
  )

  const closeCitation = () => {
    handlerRemoveCitation()
    setCitationTextTarget(null)
  }

  const sendMessage = async (opt={}, callback, setShowCitationsBlock) => {
    const {message, messageType, additionalData, selectedMessageId, citationTextTarget, typeUser, typeDialog, clientName} = opt
    const options = {}
    const citationMssageId = selectedMessageId??null

    const CALLPY_ORIGIN_URL = window.m_callpy.env.serverUrl??ENV_JS.CALLPY_ORIGIN_URL
    
    if(message?.length) {
      options.message = message
      
      if(citationMssageId){
        if(currentSiteId) {
          options.messageType = messageType??8
          options.additionalData = additionalData??{
            messagesIds: [citationMssageId],
            attached_site_id: currentSiteId,
            //attached_dialog_id: '',
            messagesIds_text: {[citationMssageId]:citationTextTarget},
            jsTime: new Date().getTime()
          }
        }
      }
      const _callback = (response) => {
        if(callback) callback(response)
      }
      options.callback = _callback

      switch (typeDialog) {
        case 'p2p':
          switch (typeUser) {
            case 'client':
                window?.m_callpy?.m_chat?.send_message_click(options)
              break;
          
            case 'manager':
    
              const managerId = ENV_JS?.MANAGER_DATA?.ID??null
              const managerToken = ENV_JS?.MANAGER_DATA?.TOKEN??null
              const managerName = ENV_JS?.MANAGER_DATA?.NAME??null
              const managerChannel = ENV_JS?.MANAGER_DATA?.CHANNEL??null
    
              const siteId = ENV_JS?.CLIENT_DATA.SITE_ID??null
              const clientId = ENV_JS?.CLIENT_DATA.ID??null
              const domaine = ENV_JS?.CLIENT_DATA.DOMAINE??null
                
    
              //Need optimisation
              const jsTime = new Date().getTime()
              const formData = new FormData()
              formData.append('action', 'send_message')
              formData.append('message', message)
              formData.append('managerName', managerName)
              formData.append('channel', managerChannel) //42f3d4b122d9c2ad5fde56e71dee6a05
              formData.append('jsTime', jsTime)
              formData.append('domain', domaine)
              formData.append('site_id', siteId) //17888
              formData.append('key', managerId) // 18196
              formData.append('type', 2)
              //formData.append('oldSiteId', 0)
              formData.append('peer_key', clientId) //504
              //formData.append('oldId', 0)
              //formData.append('newId', clientDataReducer.key)
              formData.append('peer_type', 1)
              formData.append('where', 'to_fms')
    
              const checkResponse = await fetch(`${CALLPY_ORIGIN_URL}/d/`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${managerToken}`
                },
                body: formData
              })
              if(checkResponse?.status===200) {
                const checkResponseData = await checkResponse?.json()??checkResponse?.text()
                const chatHistory = checkResponseData?.history??null
                // if(chatHistory?.length) REDUCER_DISPASH({type: SET_CHAT_HISTORY, payload:chatHistory})
                _callback(checkResponseData)
              }
              break;
          }
          break;
        case 'group':
          const dialogId =  window.m_callpy.env.grpDialogId//variablesReducer?.groupDialogId??null//281
          const clientData = window.m_callpy.client_data
          if(dialogId) {
            const formData = new FormData()
            formData.append('action', 'send_message_dialog')
            formData.append('domain',clientData?.site_info?.domain??'callpy.com') //clientDataReducer
            formData.append('text', message)
            formData.append('dialog_id', dialogId)
            formData.append('site_id', clientData.site_id)//clientDataReducer
            formData.append('part_id', clientData.key)
            formData.append('part_site_id', clientData.site_id)//clientDataReducer
            formData.append('messageType', 0)
            formData.append('clientType', 'client')
            formData.append('name', clientName)
            formData.append('key', clientData.key)//clientDataReducer
            formData.append('type', 1)
            formData.append('channel', clientData.channel)//clientDataReducer
            formData.append('where', 'to_fms')
            
            const xhr = AppXMLHttpRequest(`${CALLPY_ORIGIN_URL}/d`, {
              method: 'POST',
              body: formData
            })

            xhr.onload = () => {
              if (xhr.status===200) { 
                const ResponseData = JSON.parse(xhr.responseText)
                _callback(ResponseData)
              }
            }
          }
          
        break;
      }
      
      


      const storage = JSON.parse(localStorage.getItem(CALLPY_STORAGE_NAME))??null
      if(storage) {
        const emojis = storage?.emojis??null
        const theMostUsed = emojis?.theMostUsed??[]
        const live = emojis?.live??null
        if(live) {
          for (let i = 0; i < live.length; i++) {
            const existEmoji = theMostUsed.find(item=>item.id===live[i].id)
            if(existEmoji) {
              existEmoji.count += live[i].count
            } else {
              theMostUsed.push(live[i])
            }
          }
          live.length=0
        }
        localStorage.setItem(CALLPY_STORAGE_NAME, JSON.stringify(storage))
      }

      if(showCitationsBlock) closeCitation ()
      
    }
  }

  useEffect(() => {
    if(clientDataReducer?.site_id) {
      const sitId = clientDataReducer.site_id
      setCurrentSiteId(sitId)
    }
  }, [clientDataReducer.site_id])

  /*========> END CITATION <============*/
 



  /*========> START SELECTION <============*/
  const selectionBlock = (
    showSelectedMessagesBlock
    &&
    <div className={classNames(styles.selectionBlock)}>
      {selectedMessagesContent}
    </div>
  )

  const startSelection = (opt={}, callback) => {
    const box = (
      <div className={classNames(styles.selectionControl)}>
        <div className={classNames(styles.selectionControlInner)}>
          <button className={classNames(styles.selectionControlCloseBtn)} onClick={handlerRemoveCitation} data-close-w="false"><Icon name="close" size="20px" bg="#4A5A62" hoverBg="#E6402E"/></button>
          <span className={classNames(styles.selectionControlCounter)}>{selectedMessagesCounter}</span>
          <div className={classNames(styles.selectionControlActions)}>
            {
              (selectedMessagesCounter>1)&&
              <>
                <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="resend" size="20px" bg="#4A5A62"/></button>
                <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="edit" size="20px" bg="#4A5A62"/></button>
              </>
            }
            
            <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="copy" size="20px" bg="#4A5A62"/></button>
            <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="delete2" size="20px" bg="#E6402E" hoverBg="#E6402E"/></button>
          </div>
        </div>
      </div>
    )

    setSelectedMessagesContent(box)
    if(!showSelectedMessagesBlock) setShowSelectedMessagesBlock(true)
  }
  /*========> END SELECTION <============*/



  return {
    citationsBlock,
    startCitation,
    sendMessage,

    selectionBlock,
    startSelection,

    setSelectedMessagesCounter

  }
}