import { ENV_JS } from './../../utils/env_module'
import { useRedux } from './useRedux'
import { ADD_NEW_DIALOG, ADD_NEW_MESSAGE, SET_CHAT_HISTORY, SET_CLIENT_DATA, SET_DIALOGS_LIST, SET_MANAGERS_LIST, SET_SELECTED_FORMS } from '../../redux/types'
import { useEffect, useState } from 'react'

export const useStorage = () => {
  const {REDUCER_STATE, REDUCER_DISPASH} = useRedux()
  const CALLPY_STORAGE_NAME = ENV_JS?.CALLPY_STORAGE_NAME??null
  if(!CALLPY_STORAGE_NAME) return []


  //===> Init 
  const InitStorage = async (opt={}) => {
    const typeUser = opt?.typeUser??'client'
    const storage = JSON.parse(localStorage.getItem(CALLPY_STORAGE_NAME))??null
    const response = {}
    if(storage?.store) {
      const store = storage.store
      const mCallpy = window?.m_callpy??null
      if(!store?.chat) store.chat = {}

      //==> newMessage
      REDUCER_DISPASH({type:ADD_NEW_MESSAGE, payload:{data:null}})

      //==> History
      const chatHistory =  mCallpy?.initialize_data?.history??null
      if(chatHistory?.length) REDUCER_DISPASH({type: SET_CHAT_HISTORY, payload:chatHistory})

      //==> Client Data
      const clientData = mCallpy?.client_data
      if(clientData) {
        response.clientData = clientData
        REDUCER_DISPASH({type: SET_CLIENT_DATA, payload:clientData})
      }

      //==> ManagersList
      const managersList = mCallpy?.managers_list
      if(managersList) {
        response.managersList = managersList
        REDUCER_DISPASH({type: SET_MANAGERS_LIST, payload:managersList})
      }

      //==> Dialogs
      const dialogsList = clientData?.dialogs??null
      if(dialogsList)  {
        response.dialogsList = dialogsList
        REDUCER_DISPASH({type: SET_DIALOGS_LIST, payload:Object.values(dialogsList)})
      }

      // switch(typeUser) {
      //   case 'client':
      //     break
      //   case 'manager':
      //     break 
      // }

      
    }

    return response
    
  }

  //===> Get
  const GetStorage = (opt={}) => {
    const storageName = opt?.name??CALLPY_STORAGE_NAME
    const key = opt?.key??null
    const storage = JSON.parse(localStorage.getItem(storageName))??null
    let response = storage
    if(storage) {
      if(key) {
        response = storage[key]
      } 
    }
    return response
  }

  //===> Set
  const SetStorage = (opt={}, dispachStorage=false) => {
    const storageName = opt?.name??CALLPY_STORAGE_NAME
    const key = opt?.key??null
    const data = opt?.data??null
    let response = false
    if(storageName) {
      const storage = JSON.parse(localStorage.getItem(storageName))??null
      if(storage&&key&&data) {
        storage[key] = data
        localStorage.setItem(storageName, JSON.stringify(storage))
        response = true

        if(dispachStorage)  window.dispatchEvent(new Event('storage'))
      }
    }
    return response
  }

  //===> Remove
  const RemoveStorage = (opt={}, dispachStorage=false) => {
    const storageName = opt?.name??CALLPY_STORAGE_NAME
    const key = opt?.key??null
    let response = false
    if(storageName) {
      if(key) {
        const storage = JSON.parse(localStorage.getItem(storageName))??null
        if(storage[key]) delete storage[key]
        localStorage.setItem(storageName, JSON.stringify(storage))
      } else {
        localStorage.removeItem(storageName)
      }
      
      response = true
      if(dispachStorage)  window.dispatchEvent(new Event('storage'))
    }
    return response
  }


  //==> Listener
  const StorageListerner = (opt={}) => {

    window.addEventListener('storage', e => { 
      const storage = GetStorage()
      const serverResponse = storage?.serverResponse??null

      if(serverResponse) {
        const responseAction = serverResponse?.action
        const name = serverResponse?.name??null
        const value = serverResponse?.value??null
        const mCallpy = window?.m_callpy??null

        let action
        if(['site_settings_changed'].indexOf(responseAction)>-1) {
          action = 'client_data'
        } else 
        if(['send_message', 'send_message_self', 'send_file'].indexOf(responseAction)>-1) {
          action = 'send_message'
        } if(['fill_form', 'get_message_dialog'].includes(responseAction)) {
          action = responseAction
        }
        if(!action) return
        switch (action) {
          case 'client_data':
            const clientData = mCallpy?.client_data
            REDUCER_DISPASH({type:SET_CLIENT_DATA, payload:clientData})
            break
          case 'send_message':
            const history = serverResponse?.new_history??null
            if(history) {
              REDUCER_DISPASH({type:SET_CHAT_HISTORY, payload:history})
            }
          break
          case 'fill_form':
            REDUCER_DISPASH({type:SET_SELECTED_FORMS, payload:[serverResponse.form_id]})
            break
          case 'get_message_dialog':
            const newMessage = {
              dialogId: serverResponse.dialog_id,
              messageType: serverResponse.messageType,
              name: serverResponse.name,
              partId: serverResponse.part_id,         
              text: serverResponse.message,
              extData: serverResponse.extData,
              timestamp: serverResponse.timestamp,
              _id: {$oid:serverResponse.$id,  $id:serverResponse.$id},
              type:   serverResponse.clientType       
            }
            REDUCER_DISPASH({type:ADD_NEW_DIALOG, payload:newMessage})
            break
        }
      }
    })
  }



  return {StorageListerner, InitStorage, GetStorage, SetStorage, RemoveStorage}
}
