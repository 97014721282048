import styles from './styles.module.scss'
import { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

export const Dropdown = ({label, children, opt={},...props}) => {
  const [state, setState] = useState(()=> false)
  const [selectedOpt, setSelectedOpt] = useState(label)

  const theme = opt?.theme??null
  const className = opt?.className??null
  const labelClassName = opt?.labelClassName??null
  const listClassName = opt?.listClassName??null


  const arrowLabel = opt?.arrowLabel??null

  const btnRef = useRef(null)

  //==>
  const HandleOpenDropdown = useCallback((event) => {
    const elem = event.target
    const targetIsLabel = btnRef?.current?.contains(elem)
    if(targetIsLabel) return setState(currentState => !currentState)
    setState(false)

    //==>
    // const parentNextContent = elem.closest('[data-name="nextContent"]')
    // const dropdownContent = elem.closest('[data-name="dropdownContent"]')
    // if((elem?.dataset?.name!=='dropdownLabelBtn')&&!parentNextContent&&!dropdownContent) {
    //   if(nextContent.setNextContentShow) {
    //     setTimeout(() => {
    //       nextContent.setNextContentShow()
    //     }, 100)
    //   }
    // }
  }, [])

  useEffect(()=> {
    document.addEventListener('click', HandleOpenDropdown, true)
    return  () => {
      document.removeEventListener('click', HandleOpenDropdown, true)
    }
  }, [])

  return (
    <div className={classNames(styles.dropdown, className??'', {
      [styles.dropdownOpen]: state,
      [styles[`dropdown--${theme}`]]: theme
    })} data-name="dropdown">
       <button type={props.type || 'submit'} className={classNames(styles.dropdownLabel, labelClassName??'', {
          [styles.dropdownLabelArrow]: arrowLabel??null,
          [styles[`dropdownLabelArrow--${theme}`]]: theme
        })} ref={btnRef} data-name="dropdownLabelBtn">
          {selectedOpt??'Dropdown label'}
      </button>
      <div className={classNames(styles.dropdownContent, listClassName??'', {
        [styles.dropdownContentOpen]: state
      })} data-name="dropdownContent" >{children}</div>
    </div>
  )
}
