import styles from './styles.module.scss'
import classNames from 'classnames'
import { Icon } from '../../../ui/icons/Icon'
import { useEffect, useState } from 'react'
import { useStorage } from '../../../hooks/useStorage'
//TO DO:sans les form
export const ControlSelectedMessages = (props) => {
  const [data, setData] = useState(null)
  const {GetStorage, SetStorage, RemoveStorage} = useStorage()
  const selectedMessagesCounter = data?.length??0
  const storageKeyName = 'selectedMessagesArr'


  const handlerRemoveControl = () => {
    RemoveStorage({key:storageKeyName}, true)
  }

  useEffect(() => {
    window.addEventListener('storage', e => { 
      const storage = GetStorage()
      const selectedMessagesArr = storage?.selectedMessagesArr??null
      console.log('selectedMessagesArr', selectedMessagesArr)
      setData(selectedMessagesArr)
    })
  }, [])

  return (  
    <>
      {
        (data&&data.length>0) 
        && (
          <div className={classNames(styles.selectionControl)}>
            <div className={classNames(styles.selectionControlInner)}>
              <button className={classNames(styles.selectionControlCloseBtn)}  onClick={handlerRemoveControl} data-close-w="false"><Icon name="close" size="20px" bg="#4A5A62" hoverBg="#E6402E"/></button>
              <span className={classNames(styles.selectionControlCounter)}>{selectedMessagesCounter}</span>
              <div className={classNames(styles.selectionControlActions)}>
                {
                  (selectedMessagesCounter>1)&&
                  <>
                    <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="resend" size="20px" bg="#4A5A62"/></button>
                    <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="edit" size="20px" bg="#4A5A62"/></button>
                  </>
                }
                
                <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="copy" size="20px" bg="#4A5A62"/></button>
                <button className={classNames(styles.selectionControlActionsBtn)} data-close-w="false"><Icon name="delete2" size="20px" bg="#E6402E" hoverBg="#E6402E"/></button>
              </div>
            </div>
          </div>
        )
      }
      
    </>
  ) 
}
