export const OPEN_CLOSE_CHAT = 'OPEN_CLOSE_CHAT'
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW'
export const SET_INJECT_MODE = 'SET_INJECT_MODE'
export const SET_GROUP_DIALOG_ID = 'SET_GROUP_DIALOG_ID'

export const SET_CHAT_HISTORY = 'SET_CHAT_HISTORY'
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE'

export const SET_CLIENT_DATA = 'SET_CLIENT_DATA'
export const SET_SITE_INFO = 'SET_SITE_INFO'
export const SET_MANAGERS_LIST = 'SET_MANAGERS_LIST'
export const SET_DIALOGS_LIST = 'SET_DIALOGS_LIST'
export const SET_SELECTED_DIALOG = 'SET_SELECTED_DIALOG'
export const ADD_NEW_DIALOG = 'ADD_NEW_DIALOG'

export const SET_VARIABLES = 'SET_VARIABLES'
export const SET_SELECTED_MESSAGES = 'SET_SELECTED_MESSAGES'
export const SET_SELECTED_FORMS = 'SET_SELECTED_FORMS'


