import { SET_DIALOGS_LIST, SET_SELECTED_DIALOG , ADD_NEW_DIALOG} from '../types'


const initialState = {
  dialogs: [],
  selectedDialog:[]
}
export const dialogsReducer = (state=initialState, action) => {
  switch (action.type) {
    case  SET_DIALOGS_LIST:
      return {
        ...state,
        dialogs:action.payload
      }
    case  SET_SELECTED_DIALOG:
      //state.selectedDialog = action.payload
      return {
        ...state,
        selectedDialog:action.payload
      }
    case  ADD_NEW_DIALOG:
      //state.selectedDialog = action.payload
      state.selectedDialog.push(action.payload)
      return {
        ...state
      }
    default:
      return state
      
  }
}