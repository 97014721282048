import { getFileReader, getFileType, watchMessageAndUpdateHistory } from '../../utils/app_module'
import { ENV_JS } from '../../utils/env_module'
export const useFile = () => {


  const uploadFile = (opts, callback) => {
    const {file, typeChat, action, message, path, messageType, originFileName} = opts
    const mCallpy = window.m_callpy??null
    if(!mCallpy||!file||!typeChat) return
    const {managers_list} =  mCallpy
    const {type, key, peer_key, peer_type, channel, site_id, site_info} = mCallpy.client_data
 
    const options = {
      // obj: null,
      // file: false,
      // base64: false,
      // bar: null,
      chatfile: file,
      type,
      action: action??'send_message',
      key,
      peer_key,
      peer_type,
      channel,
      site_id,
      managerName: managers_list[peer_key].name,
      domain: site_info.domain,
      ...opts
    }

    delete options.message
    delete options.file

    const time = new Date().getTime()/1000
    const newMessage = {
      managerId: key,
      managerName: managers_list[peer_key].name,
      message,
      path,
      receiver: 0,//selected_user,
      recieved: 0,
      sender: key,
      time,
      status_read: 0,
      type: messageType
    }

    const formData = new FormData()
    for (let key in options) {
      formData.append(key, options[key])
    }

    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      if (xhr.status===200) {

        const data = JSON.parse(xhr.responseText)
        if(!data) return
        data.action = 'send_file'
        data.path = path
        const {timestamp, _id} = data

        newMessage._id = _id
        newMessage.timestamp = timestamp
        newMessage.status_sent = timestamp
        newMessage.status_received = 0
        newMessage.department_id = site_info.department_id
        //mCallpy.m_chat.history_push(site_id, 0, newMessage)
 
        if(messageType===2) {
          const fileName = data?.filename??null
          if(fileName) {
            newMessage.message = `
              <a href="${ENV_JS.CALLPY_PHP_SERVER}/upload/${fileName}" target="_blank">
              <img src="${path}"/>
              </a>
              `
          }
        }

        watchMessageAndUpdateHistory({
          history: [newMessage],
          data
        })

        if(callback) callback(xhr.response)
      } else {
        console.error(xhr)
      }
    }
    xhr.open('POST', callpy_global.php_server + "upld/" + (typeChat!="chat" ? "link" : ""), true)
    xhr.send(formData)

    return xhr
  }


  const sendFile = (opt, callback=null) => {
    const {file, messageType} = opt
    if(!file) return
    const fileType = getFileType(file.type)
    const sendDocOrMediaFile = (opt={}) => {
      const {messageType, message} = opt
      const originFileName = file.name??null
      if(!messageType) messageType=3
      const params = {file, originFileName, typeChat:'chat', messageType}
      if(message) params.message = message
      uploadFile(params, (response) => {
        if(callback) callback(JSON.parse(response))
      })
    }
    if(!fileType) return
    let options = null
    let fileName
    switch (fileType) {
      case 'image':
        getFileReader(file, async (data) => {
          data.type = 'image'
          data.nonEditable = true
          data.sending = true
          const base64 = data.base64
          //const resizeBase64 = await reduce_image_file_size(base64)
          const path = base64
          const message = ''//`<a href="${base64}" target="_blank"><img src="${base64}"/></a>`//<a><img src={base64}/></a> //'<a class="chat-a-image"><img src="' + this.options.base64 + '" alt="" /></a>'
          uploadFile({file, message, image:base64, typeChat:'chat', path, messageType:2}, (response) => {
            if(callback) callback(JSON.parse(response))
          })
        })
        break
      case 'document':
        options = {messageType:3}
        sendDocOrMediaFile(options)
      break

      case 'audio':
        options = {messageType:9}
        sendDocOrMediaFile(options)
      break
      case 'video':
        options = {messageType:10}
        sendDocOrMediaFile(options)
      break
    

    }
  }

  // const reduce_image_file_size = async (base64Str, MAX_WIDTH = 250, MAX_HEIGHT = 250) => {
  //   let resized_base64 = await new Promise((resolve) => {
  //       let img = new Image()
  //       img.src = base64Str
  //       img.onload = () => {
  //           let canvas = document.createElement('canvas')
  //           let width = img.width
  //           let height = img.height

  //           if (width > height) {
  //               if (width > MAX_WIDTH) {
  //                   height *= MAX_WIDTH / width
  //                   width = MAX_WIDTH
  //               }
  //           } else {
  //               if (height > MAX_HEIGHT) {
  //                   width *= MAX_HEIGHT / height
  //                   height = MAX_HEIGHT
  //               }
  //           }
  //           canvas.width = width
  //           canvas.height = height
  //           let ctx = canvas.getContext('2d')
  //           ctx.drawImage(img, 0, 0, width, height)
  //           resolve(canvas.toDataURL()) // this will return base64 image results after resize
  //       }
  //   });
  //   return resized_base64;
  // }

  const reduce_image_file_size = async (base64Str, opt={}) => {
    let resized_base64 = await new Promise((resolve) => {
        const maxSizeInMB = opt.maxSizeInMB??4
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024
        let quality = opt.quality??0.8

        let img = new Image()
        img.src = base64Str
        img.onload = () => {
          let a = 184
          let b = 154

          let canvas = document.createElement('canvas')
          let width, height, x, y
          width = x = img.width 
          height = y = img.height 
          
          let sx, sy, sh, sw
          sy = 0
          sh = y
          sx = (x - ((a * y) / b)) / 2
          sw = (a * y) / b
          // if ((x / y) > (a / b)) {
            
          //   //sy = 0
          //   sh = y
            
          // }

          // const aspectRatio = width / height
          // const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio)
          // const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio)
          
          // canvas.width = newWidth
          // canvas.height = newHeight
          
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img,sx,sy,sw,sh,0,0,a,b)
          const dataUrl = canvas.toDataURL('image/png', quality)
          resolve(dataUrl) // this will return base64 image results after resize
        }
    })
    return resized_base64
  }

  const  getCanvasImageBase64 = (image) => {
    let a = 184;
    let b = 154;
    let canvas =  document.createElement('canvas')
    canvas.width = a
    canvas.height = b

    let
      x = image.width,
      y = image.height

    let sx, sy, sh, sw

    if ((x / y) > (a / b)) {
        sx = (x - ((a * y) / b)) / 2;
        sy = 0;
        sh = y;
        sw = (a * y) / b;
    }

    let ctx = canvas.getContext('2d');
    ctx.drawImage(image,sx,sy,sw,sh,0,0,a,b);

    return canvas.toDataURL('image/png', 0.75)
}


  return {uploadFile, sendFile}
}