import { ADD_NEW_MESSAGE, SET_CHAT_HISTORY } from '../types'


const initialState = {}
export const chatReducer = (state=initialState, action) => {
  switch (action.type) {
    case SET_CHAT_HISTORY:
      return {
        ...state,
        history: action.payload
      }
        
    default:
      return state
      
  }
}


const initialStateNewMsg = {}
export const addNewMessageReducer = (state=initialStateNewMsg, action) => {
  switch (action.type) {
    case ADD_NEW_MESSAGE:
      return {
        ...state,
        data: action.payload.data
      }
        
    default:
      return state
      
  }
}