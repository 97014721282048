import { SET_VARIABLES, SET_ACTIVE_VIEW, SET_INJECT_MODE, SET_GROUP_DIALOG_ID } from '../types'


const initialState = {
  openDepartments: false,
  activeView: 'CHAT',
  inject: false,
  groupDialogId: null
}
export const variablesReducer = (state=initialState, action) => {
  switch (action.type) {
    case SET_VARIABLES:
      return {
        ...state,
       ...action.payload
      }
    case SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.payload
      }

    case SET_INJECT_MODE:
      return {
        ...state,
        inject: action.payload
      }
    case SET_GROUP_DIALOG_ID:
      return {
        ...state,
        groupDialogId: action.payload
      }
  
    default:
      return state
      
  }
}