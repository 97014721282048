import classNames from 'classnames'
import styles from './styles.module.scss'
import { useEffect, useRef, useState } from 'react'
import { Icon } from '../../ui/icons/Icon'
import { recordTimeWiewFormat } from '../../../utils/app_module'

export const userAudioRecord = (sendCallback) => {
  const [panelIsVisible, setPanelIsVisible] = useState(false)
  const [audioElem, setAudioElem] = useState(null)
  const [audioUrlData, setAudioUrlData] = useState(null)
  
  const [duration, setDuration] = useState(0)
  const [timerId, setTimerId] = useState()
  const [playPauseTimeId, setPlayPauseTimeId] = useState()

  const [playerAction, setPlayerAction] = useState('play')
  const [onPlayDuration, setOnPlayDuration] = useState(0)

  const audioRef = useRef()

  
  const playerPlay = (nodeAudioElem) => {
    nodeAudioElem.play()
    const _playPauseTimeId = setInterval(() => {
      const currentTime = Math.ceil(nodeAudioElem.currentTime)
      setOnPlayDuration(currentTime)
    }, 1000)
    setPlayerAction('pause')
    setPlayPauseTimeId(_playPauseTimeId)
  }

  const playerPause = (nodeAudioElem) => {
    nodeAudioElem.pause()
    setPlayerAction('play')
    if(playPauseTimeId)  {
      clearTimeout(playPauseTimeId)
      setPlayPauseTimeId(null)
    }
  }

  const playerStop = (nodeAudioElem) => {
    playerPause(nodeAudioElem)
  }

  const handlerPlayPause = (e) => {
    const audioElem = audioRef.current
    switch (playerAction) {
      case 'play':
        playerPlay(audioElem)
        break

      case 'pause':
        playerPause(audioElem)
        break
    }
  }

  const handlerRemoveRecoder = () => {
    const nodeAudioElem = audioRef.current
    playerStop(nodeAudioElem)
    setPanelIsVisible(false)
    setAudioElem(null)
    setDuration(0)
    setTimerId(null)
    setAudioUrlData(null)
    
  }

  const handlerSendFile = () => {
    if(sendCallback)  {
      //
      const {audioUrl, blob} = audioUrlData
      if(audioUrl&&blob) {
        const audioUrlSplit = audioUrl.split('/')
        let fileName = `${audioUrlSplit[audioUrlSplit.length-1]}.ogg`
        const file = new File([blob], fileName, {type:"iaudio/ogg", lastModified:new Date().getTime()}, 'utf-8')
        sendCallback({file, fileName, handlerRemoveRecoder})
        //console.log('audioUrl', audioUrl, fileName, file)
      }
      
    }
  }

  let panel = panelIsVisible&&(
    <div className={classNames(styles.audioPanel, {
      [styles.audioPanelWithPalyer]: audioElem
    })}>
      {
        !audioElem&&
        <>
          <div className={classNames(styles.audioPanelTimer)}>
            <Icon name="mic" bg="#E6402E" size="16px"/>
            <span>{recordTimeWiewFormat(duration)}</span>
          </div>
          <div className={classNames(styles.audioPanelBody)}>
            <Icon name="arrow-left" bg="#4A5A62" size="16px" />
            <span className={classNames(styles.audioPanelText)}>Для отмены проведите влево</span>
          </div>
          
          <button  className={classNames(styles.audioPanelBtn)}>
            <Icon name="mic" bg="#fff" size="24px"/>
          </button>
        </>
      }
      {
        audioElem??''
      }
      {
        audioElem&&
        <div className={classNames(styles.audioPlayerPanel)}>
          <button  className={classNames(styles.audioPlayerRemoveBtn)} onClick={handlerRemoveRecoder} data-close-w="false"><Icon name="delete" bg="#929A9D" hoverBg="#E6402E"/></button>
          <div className={classNames(styles.audioPlayerControler)}>
            <button  className={classNames(styles.audioPlayerPlayPauseBtn)} onClick={handlerPlayPause}><Icon name={playerAction} bg="#ffffff"/></button>
            <div className={classNames(styles.audioPlayerTrack)}>
              <div className={classNames(styles.audioPlayerTrackBand)}></div>
              <span className={classNames(styles.audioPlayerTimer)}>{recordTimeWiewFormat(onPlayDuration)}</span>
            </div>
          </div>
          <button  className={classNames(styles.audioPlayerSendBtn)} onClick={handlerSendFile}><Icon name="send" bg="#0077FF"/></button>
        </div>
      }
    </div>
  )

  useEffect(() => {
    if(panelIsVisible) {
      const newIntId = setInterval(() => {
        setDuration(current => current+1)
        console.log('timer==>', newIntId, duration)
      }, 1000)
      setTimerId(newIntId)
      console.log('timerId==>0', newIntId)
    }
  }, [panelIsVisible])

  useEffect(() => {
    if(audioElem&&timerId) {
      clearTimeout(timerId)
      const nodeAudioElem = audioRef.current
      if(nodeAudioElem) {
        //===>> Duration
        nodeAudioElem.onloadedmetadata = (e) => {
          nodeAudioElem.currentTime = Number.MAX_SAFE_INTEGER
          nodeAudioElem.ontimeupdate = () => {
            nodeAudioElem.ontimeupdate = () => null
            nodeAudioElem.currentTime = 1.0
            nodeAudioElem.currentTime = 0
            const duration = Math.ceil(nodeAudioElem.duration)
            setOnPlayDuration(duration)
            return null
          }
        }
        //==>>> OnEnd
        nodeAudioElem.onended = () => {
          playerStop(nodeAudioElem)
        }
      }
       
    }
  }, [audioElem])


  const start = (opt={}) => {
    const {btn} = opt
    if(navigator?.mediaDevices?.getUserMedia) {
      const device = navigator.mediaDevices.getUserMedia({audio:true, video:false})
      let chunks = []

      device.then(stream => {
        let mediaRecord = new MediaRecorder(stream)
        setPanelIsVisible(true)
        //=>Start record
        mediaRecord.start()

        btn.addEventListener('mouseup', e => {
          mediaRecord.stop()
        })

        //==>>
        mediaRecord.ondataavailable = e => {
          chunks.push(e.data)
        }

        mediaRecord.onended = e => {
          audioElem.currentTime = 0
        }
   
        //=>>> When stoped recoding
        mediaRecord.onstop = e => {
          let file
          const blob = new Blob(chunks, {'type': 'audio/mp3; codecs=opus'}) //audio/ogg;
          const audioUrl = window.URL.createObjectURL(blob)
          stream.getTracks().forEach(track => track.stop())

          const audioElem = <audio className={styles.audioElem} src={audioUrl} controls={true}  ref={audioRef}/>
          chunks = []
          setAudioElem(audioElem)
          setAudioUrlData({audioUrl, blob})
        }
      })
    }
  }

  return {start, panel}
}
