import classNames from 'classnames'
import { nanoid } from 'nanoid'
import { Field } from './fields/Field'
import styles from './styles.module.scss'
import { Icon } from '../../ui/icons/Icon';
import { useRef,  useState } from 'react'
import { ENV_JS } from '../../../utils/env_module'

export const Form = ({data}) => {
  const formRef = useRef()
  const [sendedForm, setSendendForm] = useState(false)
  const additionalData = data?.additional_data?JSON.parse(data.additional_data):null
  const formType = data?.form_type??null
  const formId = Number(data?.id)??null

  console.log('form', data)
  const formContent = data?.fields?.map(items => {
    const keyVal = nanoid()
    data.formId = formId
    return <Field key={keyVal} data={items} additionalData={additionalData}/> 
  })
  


  const handlerSubmit = e => {
    e.preventDefault()
    const form = e.target
    if(formType=='BUTTONS'){
      setSendendForm(true)
    } else {
      const formData = new FormData(form)
      const fields = {}
      const data = {}
      const fieldData = {}
      const emptyKeys = []
      const emptyRequiredKeys = []
      for(let [key, value] of formData) {
        let fieldName = key
        const fieldId = fieldName.split('_')[1]
        data[key] = value
        fieldData[`data[${fieldId}]`] = value
        console.log('field', key, value, fieldId)
      }
  
      const mCallpy = window?.m_callpy??null
      if(mCallpy) {
        const options = {
          action: 'addFormData',
          site_id: mCallpy.client_data.site_id,
          site: mCallpy.client_data.site,
          key: mCallpy.client_data.key,
          channel: mCallpy.client_data.channel,
          formType: "siteForm",
          form_id: formId,
          ...fieldData
        }
  
        const xhr = new XMLHttpRequest()
        xhr.onload = () => {
          setSendendForm(true)
        }
        const formData = new FormData()
        for (let key in options) {
          formData.append(key, options[key])
        }
        xhr.open('POST', ENV_JS.CALLPY_PHP_SERVER + "/d/", true)
        xhr.send(formData)
      }
      console.log('form', form, fields, data, fieldData)
    }
   
    
  //window?.m_callpy?.m_chat?.send_message_click(options)
    // window.m_callpy.m_user.formData({
    //   formType: "siteForm",
    //   form_id: id,
    //   data: fieldData
    // });
    
  }

  return (
    <div className={classNames(styles.formMessage, {
      [styles['formMessage--type-buttons']]: (formType=='BUTTONS')?true:false
    })}>
      {
        sendedForm?
        <div className={classNames(styles.formMessageAlert)}>
          Заполнена форма
        </div>
        :
        <div className={classNames(styles.formMessageBody)}>
          <div className={classNames(styles.formMessageBodyContent)}>
            <span className={classNames(styles.formMessageBodyContentTail)}></span>
            <div className={classNames(styles.formMessageTitle)}>{data?.name??'Form'}</div>
              <form data-id={formId} data-type={formType} ref={formRef} onSubmit={handlerSubmit}>
                {formContent}
                {(formType!=='BUTTONS')?<button className={classNames(styles.formMessageSendBtn)}>Отправить</button>:''}
              </form>
          </div>
        </div>
      }
      
    </div>
  )
}


