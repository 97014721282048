import { COLORS } from './colors.module'
import { SIZES } from './sizes.module'
let currentTheme = 'default'

/**
 * 
 * @param {*} color 
 * @param {*} type (primary | chat)
 * @returns 
 */
export const getColor = (opt={}) => {
  const name = opt.name??'black'
  const level = opt.level? (opt.level<10?`0${opt.level}`:opt.level):null
  const child = opt.child??null
  const type = opt.type??'chat'
  
  const names = currentTheme === 'default' ? COLORS.defaultThemeColors : COLORS.custumThemeColors


  return level? names[type][name][level]:(child?names[type][name][child]:names[type][name])
}


/**
 * 
 * @param {*} name 
 * @param {*} type (primary | chat)
 * @returns 
 */
export const getSize = (opt={}) => {
  const name = opt.name??'text'
  const level = opt.level? (opt.level<10?`0${opt.level}`:opt.level):null
  const type = opt.type??'chat'

  const sizes = SIZES
  return level? sizes[type][name][level]:sizes[type][name]
}

