import classNames from 'classnames'
import styles from './styles.module.scss'
import { useRedux } from '../../hooks/useRedux'
import { useSelector } from 'react-redux'
import { Fragment, useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import { Message } from '../../views/message/Message'

export const DialogMessages = () => {
  const {REDUCER_DISPASH} = useRedux()
  const dialogsReducer =  useSelector(state => state.dialogsReducer)
  const [history, setHistory] = useState([])
  const trackStrip = useRef()

  let countNum = 0
  const dialogList = history.map((item, index, arr) => {
    countNum++
    const keyId = nanoid()
    let injectDate = false
    let dayDate = null

    const prevItem = index>0?arr[index-1]:null
    const nexItem = (index+1<=arr.length-1)?arr[index+1]:null
    const concatMsg = Number(prevItem?.partId)===Number(item?.partId)
    const nextConcatMsg = Number(nexItem?.partId)===Number(item?.partId)
    const isSameUser =  Number(prevItem?.partId)===Number(nexItem?.partId)
    const type = Number(nexItem?.messageType)??0

    let prevTimestampDate = prevItem?prevItem.timestamp:null
    let prevDate = new Date(prevTimestampDate*1000)

    let messageTimestampDate = item.timestamp
    let messageDate = new Date(messageTimestampDate*1000)


    const partId = Number(item?.partId)??null
    const clientId = Number(window?.m_callpy?.client_data?.key)
    const isMe = (partId===clientId)

    const messageData = {
      category:2,
      from:isMe?1:2,
      message: item?.text??'',
      type,
      item
    }

    return <Fragment  key={keyId}>
      <Message
        data={messageData}
        opt={{
          concatMsg,
          prevItem,
          nextConcatMsg,
          isSameUser
        }}
        /> 
    </Fragment>
  })

  

  const scrollTrackToBottom = () => {
    const block = trackStrip?.current??null
    if(block) {
      const scrollHeight = block.scrollHeight
      block.scrollTo({top:scrollHeight, behavior:'smooth'})
    }
  }

  

  useEffect(() => {
    const dilalogHistory = dialogsReducer?.selectedDialog??null
    if(dilalogHistory?.length) {
      setHistory(dilalogHistory)
      setTimeout(() => {
        //scrollTrackToBottom()
        if(history?.length===countNum) {
          scrollTrackToBottom()
        }
      }, 200)
    }
  }, [dialogsReducer])

  
  return (
    <div className={classNames(styles.dialogTrack)} ref={trackStrip}>{dialogList}</div>
  )
}
