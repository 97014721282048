import { SET_CLIENT_DATA } from '../types'


const initialState = {}
export const clientDataReducer = (state=initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
      
  }
}