import { ENV_JS } from './env_module'
/**
 * -------------------------------------------
 *  URL
 * -------------------------------------------
 */
export const urlPathName = window.location.pathname;
export const urlHostName = window.location.hostname
export const url = urlPathName.split('/');
export const urlHref = window.location.href;
export const urlSearch = window.location.search;
export const urlDecode = decodeURIComponent
export const $_GET = urlSearch.substring(1).split("&").reduce((a, c) => {
  const [k,v]= c.split('=')
  a[urlDecode(k)] = urlDecode(v)
  return a
}, {})

/**
 * -------------------------------------------
 *  windowDispachStorage
 * -------------------------------------------
 */
export const windowDispachStorage = (opt={}) => {
  const data = opt?.data??null
  const key = opt?.key??null
  const keyData = opt?.keyData??null
  const setStorage = opt?.setStorage??true
  const appStorageName = ENV_JS.CALLPY_STORAGE_NAME
  let appStorage = JSON.parse(localStorage.getItem(appStorageName))??null
  if(!appStorage) appStorage = {}
  if(data) {
    if(key&&keyData) data[key] = keyData
    appStorage.serverResponse = data
    console.log('storage ===>', appStorage)
    if(setStorage) localStorage.setItem(appStorageName, JSON.stringify(appStorage))
    window.dispatchEvent(new Event('storage') )
  }
}

/*
---------------------------------------
 *  Watch Message And Update History 
---------------------------------------
 */
export const watchMessageAndUpdateHistory = (opt={}) => {
  const mCallpy = window?.m_callpy??null
  const history = opt?.history??[]
  const data = opt?.data??null
  if(!data) return
  if(mCallpy?.initialize_data?.history) {

    const initHistory = mCallpy.initialize_data.history
    const addedHistory = history
    const newListHistory = [...initHistory, ...addedHistory]
    //console.log('newListHistory', newListHistory)
    windowDispachStorage({data, key:'new_history', keyData:newListHistory})
  }
}

/*
-------------------------------
 *  If is Link
-------------------------------
 */
export const linkify = text => {
  const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig // eslint-disable-line
  return text.replace(urlRegex, function(url) {
    return `<a href="${url}"class="cpn-linkify">${url}</a>`
  })
}


/*
-------------------------------
 *  Get File Type
-------------------------------
 */
export const getFileType = (fileType) => {
  let type
  if(fileType) {
    if(fileType.indexOf('image')>-1) {
      type = 'image'
    } else if(fileType.indexOf('audio')>-1) {
      type = 'audio'
    } else if(fileType.indexOf('video')>-1) {
      type = 'video'
    } else {
      type = 'document'
    }
  }
  
  return type
}

/*
-------------------------------
 *  Get File Reader
-------------------------------
 */
export const getFileReader = (file, callback) => {
  const reader = new FileReader()
  reader.onload = (evt) => {
      const image = new Image()
      image.onload = () => {
        const base64 = getCanvasImageBase64(image)
        callback({file: file, base64: base64})
      }
      image.src = evt.target.result
  }

  reader.readAsDataURL(file)
}


/*
-------------------------------
 *  Get Canvas Image Base64
-------------------------------
 */
export const getCanvasImageBase64 = image => {
  let a = 154
  let b = 154
  const canvas = document.createElement('canvas')
  canvas.width = a
  canvas.height = b

  const x = image.width
  const  y = image.height

  let sx, sy, sh, sw
  if ((x / y) > (a / b)) {
      sx = (x - ((a * y) / b)) / 2
      sy = 0
      sh = y
      sw = (a * y) / b
  }else{
      sy = (y - ((a * x) / b)) / 2
      sx = 0
      sw = x
      sh = (a * x) / b
  }
  const ctx = canvas.getContext('2d')
  ctx.drawImage(image, sx, sy, sw, sh, 0, 0, a, b)
  return canvas.toDataURL('image/png', 0.75)
}

/*
-------------------------------
 *  Get Path of link 
-------------------------------
 */
export const getHrefPath = (text) => {
  let response 
  const urlRegex =/(href="(https|http):\/\/[a-z0-9+&@#\/%?=~_-|!:.;]*("|'))/gm
  const results = text.match(urlRegex)
  if(results) {
    response = results[0].replace(/href="|">|"|'/gm, '')
  }
  return response
}

/*
-------------------------------
 *  FORMAT RECORD TIME
-------------------------------
 */
export const recordTimeWiewFormat = duration => {
  let s = 0
  let m = 0

  if(duration>=60) {
    m = Math.floor(duration/60)
    s = duration - (m*60)
  } else {
    s = duration
    m = 0
  }
  //s = (s<10)? `0${s}`:s
  return `${String(Math.ceil(m)).padStart(2, '0')}:${String(Math.ceil(s)).padStart(2, '0')}`
}


/*
-------------------------------
 *  AppXMLHttpRequest
-------------------------------
 */
export const AppXMLHttpRequest = (url, options={}) => {
  const method = options?.method??'GET'
  const header = options?.header??null
  const body = options?.body??''
  let response = null
  if(url) {
    const xhr = new XMLHttpRequest()
    try {
      
      xhr.open(method, url, true)
      if(header&&Array.isArray(header)) {
        //xhr.setRequestHeader('Content-Type', 'application/json')
         header.forEach(item => {
          const itemArr = Object.entries(item)
          console.log('itemArr', itemArr[0], itemArr[1])
          //xhr.setRequestHeader(itemArr[0], itemArr[1])
         })
       }
      xhr.send(body)
    } catch (e) {
      console.error(e)
      return e
    }
    // return xhr.onload = () => {
    //   if (xhr.status===200) {
    //     const data = JSON.parse(xhr.responseText)
    //     response = data
    //   } else {
    //     console.error(xhr)
    //   }
    //   response = {
    //     data,
    //     xhr
    //   }
    //   return response
    // }
    response = xhr

  }
  return response
 
}


/*
-------------------------------
 *  AppFetch
-------------------------------
 */
export const AppFetch = (url, options={}) => {
 
}