import styles from './styles.module.scss'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { getColor } from '../../../assets/Js/modules/styles/styles.module'
import { OPEN_CLOSE_CHAT } from '../../../redux/types'
import {useSelector ,  useDispatch} from 'react-redux'

import defaultAvatar from './img/default-avatar.svg'

// const useStyles = createUseStyles({
//   selectBody: {
//     width: (selectWd) => selectWd    
//   }
// })


export const Label = () => {
  const labelBgColor = getColor({color:'bg', type:'label'})
  const labelCloseBtnBg = getColor({color:'closeBtnBg', type:'label'})

  const defaultReducer = useSelector(state => state.defaultReducer)
  const REDUCER_DISPASH = useDispatch()

  //==>>> Open Close Chat
  const handlerOpenCloseChat = (newState=null) => {
    if(!newState) {
      newState = !defaultReducer?.chatIsOpen??false
    }

    REDUCER_DISPASH({
      type: OPEN_CLOSE_CHAT,
      payload: newState
    })

  }

  const useStyles = createUseStyles({
    labelUserBtn: {
      backgroundColor: labelBgColor
    },
    labelCloseBtn: {
      backgroundColor: labelCloseBtnBg
    }
  })
  const classes = useStyles()
  return (
    <div className={classNames(styles.label, classes.label)} data-name='chat-label'>
      <button className={classNames(styles.labelUserBtn, classes.labelUserBtn)} onClick={()=> handlerOpenCloseChat(true)}>
        <span className={classNames(styles.labelUserAvatarBox, classes.labelUserAvatarBox)}>
          <img src={defaultAvatar}/>
        </span>
      </button>
      <button className={classNames(styles.labelCloseBtn, classes.labelCloseBtn)}></button>
    </div>
  )
}
