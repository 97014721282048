export const ENV_JS = {

  CALLPY_PHP_SERVER: 'https://kkombeto.dev.callpy.com/',
  CALLPY_SERVER: 'kkombeto.dev.callpy.com',
  CALLPY_LANGUAGE: 'ru',
  CALLPY_RPLX_SERVER: 'https://rp.kkombeto.dev.callpy.com',
  CALLPY_ORIGIN_URL: 'https://kkombeto.dev.callpy.com',
  CALLPY_CLIENT_DATA: {
    SITE_URL: 'https://test.dev-spw.com/',
    SERVER: 'test.dev-spw.com'
  },
  
  CALLPY_STORAGE_NAME: 'CallpyApp',
  CALLPY_SOURCE_PATH: 'https://cdm.dev-spw.com/callpy/react-chat/build/',

  MANAGER_DATA: {
    ID: 18196,
    TOKEN: 'fd5f67c28fc3b54bcd3e1e4d1395f88d',
    CHANNEL: '42f3d4b122d9c2ad5fde56e71dee6a05',
    NAME: 'Дмитрий'
  },

  CLIENT_DATA: {
    ID: 504,
    SITE_ID: 17888,
    DOMAINE: 'test.dev-spw.com'
  }

}