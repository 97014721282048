import classNames from 'classnames'
import styles from './styles.module.scss'
import { createUseStyles } from 'react-jss'
import { getColor } from '../../../assets/Js/modules/styles/styles.module'
import { Tooltip } from '../../ui/tooltip/Tooltip'
import { Icon } from '../../ui/icons/Icon'
import { Linkify } from '../../ui/linkify/Linkify';
import HTMLReactParser from 'html-react-parser'
import { getHrefPath } from '../../../utils/app_module'
import { AudioPlayer } from '../../ui/audio-player/AudioPlayer'
import {useSelector ,  useDispatch} from 'react-redux'
import { SET_VARIABLES } from '../../../redux/types'
import { useState, useEffect } from 'react'
import { useStorage } from '../../hooks/useStorage'
import { Form } from '../forms/Form'



export const Message = ({data, opt={}, ...props}) => {
  const REDUCER_DISPASH = useDispatch()
  const [isSelectedMessage, setIsSelectedMessage] = useState(false)
  const clientDataReducer = useSelector(state => state.clientDataReducer)
  const {GetStorage, SetStorage} = useStorage()
  const setCitationTextTarget = opt?.setCitationTextTarget??null
  const {startCitation, startSelection, setSelectedMessagesCounter} = opt?.useMessageHook??{}

  const colorMessageName = getColor({name:'user', child:'name'})
  const colorMessageTime = getColor({name:'user', child:'time'})
  const colorBlack = getColor({name:'black', type:'primary'})

  const hoursPosition = opt?.hoursPosition??'bottom' // top || bottom
  const concatMsg = opt?.concatMsg??false // true || false
  const nextConcatMsg = opt?.nextConcatMsg??false // true || false
  const isSameUser = opt?.isSameUser??false

  const hoursPositionOnTheTop = (hoursPosition==='top')

  const item = data?.item??null
  const messageId = item?._id?.$id??null
  const type = item.messageType??item.type??data?.type??null // 0:text || 1: || 2:image || 3:file || 4:systemMessage || 5: || 6: || 7: || 8:citation || 9:audio || 10:Video ||
  const category = data?.category??null // 1:system || 2:user
  const message = data?.message??null
  const path = data?.path??null
  let pathElem = null
  const from = data?.from??null // 1:me||2:interlocutor
  const partId = Number(item?.partId)??null

  

  const timestamp = item?.timestamp??item?.time??null
  const date = timestamp? new Date(timestamp*1000):null
  const _hours = date?.getHours()??null
  const _minutes = date?.getMinutes()??null
  const hours = (_hours!==null)?String(_hours).padStart(2, '0'):'xx'
  const minutes = (_minutes!==null)?String(_minutes).padStart(2, '0'):'xx'
  const messageTime = `${hours}:${minutes}`
  let custumMessage = null
  let messageWithCitation = false


  const handlerHighlightedSelectedText = () => {
    if(window.getSelection().toString().length){
      let exactText = window.getSelection().toString();
      if(startCitation&&exactText&&messageId){
        const userName = from===1?'Вы':item?.managerName??'-'
        const msgId = messageId??null
        startCitation({userName,text:exactText, messageId:msgId})
        REDUCER_DISPASH({type: SET_VARIABLES, payload:{selectedMessageId:msgId, citationTextTarget:exactText}})
      }
    }
  }


  if(from&&type>=0&&message&&category&&item) {
    const useStyles = createUseStyles({
      messageName: {
        color: colorMessageName
      },
      messageTime: {
        color: colorMessageTime 
      }
    })
    const classes = useStyles()


    //===> Status message
    let selectedStatusLabel,selectedStatusTimestamp
    if(item?.status_read) {
      selectedStatusLabel = 'Просмотрено'
      selectedStatusTimestamp = item.status_read
    } else if(item?.status_received){
      selectedStatusLabel = 'Получено'
      selectedStatusTimestamp = item.status_received
    } else if(item?.status_sent){
      selectedStatusLabel = 'Доставлено'
      selectedStatusTimestamp = item.status_sent
    }
    const selectedStatusDate = new Date(selectedStatusTimestamp*1000)
    const statusTooltipMessage = `${selectedStatusLabel} в ${String(selectedStatusDate.getHours()+1).padStart(2, '0')}:${String(selectedStatusDate.getMinutes()+1).padStart(2, '0')}`
    const handlerMessageSelect = () => {
      // setIsSelectedMessage(current => !current)
      // const storage = GetStorage()
      // let selectedMessagesArr = storage?.selectedMessagesArr??null
      // if(!selectedMessagesArr) selectedMessagesArr = []
      // const indexMsgId = selectedMessagesArr.indexOf(messageId)
      // if(indexMsgId>-1){
      //   selectedMessagesArr.splice(indexMsgId, 1)
      // } else {
      //   selectedMessagesArr.push(messageId)
      // }
      // SetStorage({key:'selectedMessagesArr', data:selectedMessagesArr}, true)
    }
    const storage = GetStorage()
    useEffect(() => {
      const storageControl = () => {
        const storage = GetStorage()
        
        const selectedMessagesArr = storage?.selectedMessagesArr??null
       if(!selectedMessagesArr&&isSelectedMessage) {
        setIsSelectedMessage(false)
       }
      }
      window.addEventListener('storage', storageControl)
      return storageControl
    }, [isSelectedMessage])
    
    //====> FROM
    let userName
    let fromName 
    switch (from) { 
      case 1:
        fromName = 'me'
        userName = 'Вы'
        break
      case 2:
        fromName = 'interlocutor'
        userName = item?.managerName??(item?.name?.length?item?.name:`Клиент ${partId}`)??'-'
        break
      case 3:
        fromName = 'system'
        userName = null
        break
    }

    //====> TYPE
    let typeName
    let formType = null
    switch (type) {
      case 0:
        typeName = 'text'
        break
      case 1:
        typeName = 'missed_call'
        break
      case 2:
        typeName = 'image'
        break
      case 3:
        typeName = 'file'
        break
      case 4:
        typeName = 'systeme_message'
        break
      case 5:
        typeName = 'typing_message'
        break
      case 6:
        typeName = 'auto_message'
        break
      case 7:
        typeName = 'form_fill'
        const formData = item?.form??null
        if(formData) {
          formType = formData?.form_type??null
          custumMessage = <Form data={item?.form}/>
        }
        
        break
      case 8:
        typeName = 'citation'
        break
      case 9:
        typeName = 'auddio'
        const regExp = new RegExp()
        const path = getHrefPath(message)
        if(path) {
          custumMessage = <AudioPlayer url={path}/>
        }
        
        break
      case 10:
        typeName = 'video'
        break
     
    }

    const finalMessage = custumMessage??HTMLReactParser(message??'<--->')
    let _message = finalMessage
    if(item.extData?.length) {
      const citationData = item.extData[0]
      messageWithCitation = true
      const citationBlox = (
        <div className={classNames(styles.messageCitation)}>
            <div className={classNames(styles.messageCitationHead)}>
              <div className={classNames(styles.messageCitationOriginAuthor)}>{citationData.managerName}</div>
            </div>
            <div className={classNames(styles.messageCitationBody)}>{citationData.message}</div>
        </div>
      )
      _message = <>{citationBlox} <div className={classNames(styles.messageCitationComment)}>{finalMessage}</div></>
    }

    //====> CATEGORY
    let categoryName
    let content = ''
    switch (category) {
      case 1:
        categoryName = 'system'
        content = (
          <>
            <div className={classNames(styles.systemMessage)}>
            <div className={classNames(styles.systemMessageContent)}>  {/* dangerouslySetInnerHTML={{__html: message}} */}
              {/* <Linkify>{message}</Linkify>  */}
              {message}
              </div>
            </div>
          </>
        )
        break
      case 2:
        categoryName = 'user'
        const data = new Date(item.timestamp*1000)
        const tooltipMessage = `${String(data.getDate()).padStart(2, '0')}-${String(data.getMonth()+1).padStart(2, '0')}-${data.getFullYear()}`
        const messageHoursBox = (<>
          <Tooltip message={tooltipMessage} className={classNames(styles.messageHoursToolpip, styles[`messageHoursToolpip--${hoursPosition}`])} msgClassName={styles.messageHoursToolpipMsg} opt={{position:'top'}}>
            <span className={classNames(styles.messageHours, classes.messageTime)}>{messageTime}</span>
          </Tooltip>
        </>)
        content = (
          <>
            { 
              !concatMsg
              &&
              <>
                <div className={classNames(styles.messageTop)}>
                  <span className={classNames(styles.messageUserName, classes.messageName)}>{userName??'User Name'}</span>
                  {hoursPositionOnTheTop&&messageHoursBox}
                </div>
              </>
            }
            <div className={classNames(styles.messageBody)}>
              <div className={classNames(styles.messageUserAvatar, {[styles.messageUserAvatarNextConcat]: nextConcatMsg})}>
                {
                  !concatMsg
                  &&
                  <>
                    <div className={classNames(styles.messageUserAvatarInner)}><Icon name='user' bg="#101828"/></div>
                    {!hoursPositionOnTheTop&&messageHoursBox}
                  </>
                }
              </div>
              <div className={classNames(styles.messageContent)}>
                <div className={classNames(styles.messageContentBody)}>
                  {!concatMsg&&<span className={classNames(styles.messageContentTail)}></span>}
                  <div className={classNames(styles.messageContentInner)} onMouseUp={handlerHighlightedSelectedText}>
                    {_message}
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(styles.messageFooter)}>
                <div className={classNames(styles.messageFooterInner)}>
                  {
                    !nextConcatMsg&&
                    <Tooltip message={statusTooltipMessage} className={classNames(styles.messageStatusToolpip)} msgClassName={styles.messageStatusToolpipMsg} opt={{position:'top'}}>
                      <span className={classNames(styles.messageStatus)}>{selectedStatusLabel}</span>
                    </Tooltip>
                  }
                </div>
            </div>
          </>
        )
        break
    }

    return (
      <div className={classNames(styles.message,  styles[`message--${categoryName}`], styles[`message--${fromName}`], styles[`message--type-${typeName}`], {
        [styles.messageConcat]: concatMsg,
        [styles.messageNextConcat]: nextConcatMsg,
        [styles.messageLastRow]: !nextConcatMsg,
        [styles.messageSelected]:isSelectedMessage,
        [styles[`message-form--${formType}`]]:formType
      })} >
        {category===2&&<button className={classNames(styles.messageSelectorBtn, {[styles.messageSelectorBtnSelected]:isSelectedMessage})} onClick={handlerMessageSelect} data-close-w="false"></button>}
        <div className={classNames(styles.messageInner, {[styles.messageSelectorBtnSelected]:isSelectedMessage})}>
          {content}
        </div>
      </div>
    )
  }
}
