import classNames from 'classnames'
import styles from './styles.module.scss'
import { useState, useEffect } from 'react'
import { useRedux } from '../../../hooks/useRedux'
import { nanoid } from 'nanoid'
import { DepartmentsItem } from '../departmentsItem/DepartmentsItem'
import { Icon } from '../../../ui/icons/Icon'
import { SET_ACTIVE_VIEW } from '../../../../redux/types'

export const DepartmentsList = ({data, ...props}) => {
  const {REDUCER_STATE, REDUCER_DISPASH} = useRedux()

  //const handlerCloseDepartments = () => REDUCER_DISPASH({type:SET_VARIABLES, payload:{openDepartments:false}})
  const handlerSetChatView = () => REDUCER_DISPASH({type: SET_ACTIVE_VIEW, payload:'CHAT'})

  const _departments = window.m_callpy?.client_data?.site_info?.departments??null
  const departments = _departments?Object.values(_departments):null
  //==>> DEPARTMENTS
  let DEPARTMENTS
  if(departments?.length) {
    console.log('departments==>2', departments)

    DEPARTMENTS = departments.map(item => {
      const keyId = nanoid()
      return <DepartmentsItem key={keyId} data={item}/>
    })
  }

  console.log('mChat', departments)


  const departmentsListView =  DEPARTMENTS??'departmentsListView'
  return (
    <div className={classNames(styles.departmentsList)}>
      <div className={classNames(styles.departmentsListInner)}>{departmentsListView}</div>
      <button className={classNames(styles.departmentsListGobackBtn)} onClick={handlerSetChatView} data-close-w="false">
        <Icon name="go-back" bg="#0077FF"/>
        <span>Вернуться назад</span>
      </button>
    </div>
  )
}
