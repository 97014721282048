import classNames from 'classnames'
import styles from './styles.module.scss'

export const Dialog = ({name, timestamp, totalUnread=0, lastMsg, userAvatar=null, type="group", onClick}) => {
  const todaysDate = new Date()
  const dialogDayDate = new Date(timestamp*1000)
  const dialogDayDateIsToday = dialogDayDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)
  //console.log('dialogDayDateIsToday', dialogDayDateIsToday)
  const dialogDateTime = dialogDayDateIsToday? `${dialogDayDate.getHours().toString().padStart(2, '0')}:${dialogDayDate.getMinutes().toString().padStart(2, '0')}`:`${dialogDayDate.getDate().toString().padStart(2, '0')}.${dialogDayDate.getMonth().toString().padStart(2, '0')}.${dialogDayDate.getDay()}`
  const groupChat = type=="group"
  return (
    <li className={classNames(styles.dialogs__sectionListItem)} onClick={onClick} data-close-w="false">
      <div className={classNames(styles.dialogs__sectionListItemUser)}>
        <div className={classNames(styles.dialogs__sectionListItemUserAvatar)}>
          <div className={classNames(styles.dialogs__sectionListItemUserAvatarImgs)}>
            {
              (groupChat)?
              <>
                <div className={classNames(styles.dialogs__sectionListItemUserAvatarImgsElem)}></div>
                <div className={classNames(styles.dialogs__sectionListItemUserAvatarImgsElem)}></div>
                <div className={classNames(styles.dialogs__sectionListItemUserAvatarImgsElem)}></div>
                <div className={classNames(styles.dialogs__sectionListItemUserAvatarImgsElem)}></div>
              </>
              :
              <>
                <img src={userAvatar}/>
              </>
            }
            
          </div>
          {!groupChat&&<span className={classNames(styles.dialogs__sectionListItemUserOnline)}></span>}
        </div>
      </div>
      <div className={classNames(styles.dialogs__sectionListItemInfos)}>
        <div className={classNames(styles.dialogs__sectionListItemLeft)}>
          <div className={classNames(styles.dialogs__sectionListItemUserName)}>{name}</div>
          <div className={classNames(styles.dialogs__sectionListItemUserMessage)}>{lastMsg}</div>
        </div>
        <div className={classNames(styles.dialogs__sectionListItemRight)}>
          <div className={classNames(styles.dialogs__sectionListItemDateTime)}>{dialogDateTime}</div>
          <div className={classNames(styles.dialogs__sectionListItemUnreadMsg)}>{totalUnread}</div>
        </div>
      </div>
    </li>
  )
}
