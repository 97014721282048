import { combineReducers } from 'redux'
import { defaultReducer } from './defaultReducer'
import { variablesReducer } from './variablesReducer'
import { chatReducer, addNewMessageReducer } from './chatReducer'
import { managersListReducer } from './managersListReducer'
import { clientDataReducer } from './clientDataReducer'
import { selectedMessagesReducer } from './selectedMessageReducer'
import { selectedFormsReducer } from './selectedFormsReducer'
import { dialogsReducer } from './dialogsReducer'




export const appReducer = combineReducers({
  defaultReducer,
  variablesReducer,
  chatReducer,
  addNewMessageReducer,
  clientDataReducer,
  managersListReducer,
  selectedMessagesReducer,
  selectedFormsReducer,
  dialogsReducer

})

export const rooteReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}