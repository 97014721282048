import { SET_SELECTED_MESSAGES } from '../types'


const initialState = {
  selectedMessagesArr: []
}
export const selectedMessagesReducer = (state=initialState, action) => {
  switch (action.type) {
    case  SET_SELECTED_MESSAGES:
      return {
        ...state,
       ...action.payload
      }
    default:
      return state
      
  }
}