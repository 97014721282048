const defaults = {
  text: '1rem'
}



export const SIZES = {
  defaults,
  chat: {
    width: '320px',
    minHeight: '350px'
  }
}